import { NgModule } from '@angular/core';

import {
	DeviceBehaviorPluginsModule,
	DeviceImageBehaviorPluginService
} from '@shure/shared/angular/device-behavior/core';
import { DeviceImageProvider } from '@shure/shared/angular/ui/components/device-image';

import { Ad600DeviceBehaviorPlugin } from './ad600.device-behavior';
import { AdtdDeviceBehaviorPlugin } from './adtd.device-behavior';
import { AdtqDeviceBehaviorPlugin } from './adtq.device-behavior';
import { AniusbDeviceBehaviorPlugin } from './aniusb.device-behavior';
import { ImxComputeDeviceBehaviorPlugin } from './imx-compute.device-behavior';
import { ImxRoomDeviceBehaviorPlugin } from './imx-room.device-behavior';
import { McrDeviceBehaviorPlugin } from './mcr.device-behavior';
import { Mxa710DeviceBehaviorPlugin } from './mxa710.device-behavior';
import { Mxa901DeviceBehaviorPlugin } from './mxa901.device-behavior';
import { Mxa902DeviceBehaviorPlugin } from './mxa902.device-behavior';
import { Mxa920DeviceBehaviorPlugin } from './mxa920.device-behavior';
import { MxnAmpDeviceBehaviorPlugin } from './mxn-amp.device-behavior';
import { Mxn5cDeviceBehaviorPlugin } from './mxn5c.device-behavior';
import { Mxw1xDeviceBehaviorPlugin } from './mxw1x.device-behavior';
import { Mxw2xDeviceBehaviorPlugin } from './mxw2x.device-behavior';
import { Mxw6xDeviceBehaviorPlugin } from './mxw6x.device-behavior';
import { Mxw8xDeviceBehaviorPlugin } from './mxw8x.device-behavior';
import { MxwApxDeviceBehaviorPlugin } from './mxwapx.device-behavior';
import { Mxwapxd2DeviceBehaviorPlugin } from './mxwapxd2.device-behavior';
import { MxwNdx4DeviceBehaviorPlugin } from './mxwndx4.device-behavior';
import { MxwNdx8DeviceBehaviorPlugin } from './mxwndx8.device-behavior';
import { P300DeviceBehaviorPlugin } from './p300.device-behavior';

@NgModule({
	imports: [
		DeviceBehaviorPluginsModule.forRoot([
			Ad600DeviceBehaviorPlugin,
			AdtdDeviceBehaviorPlugin,
			AdtqDeviceBehaviorPlugin,
			AniusbDeviceBehaviorPlugin,
			ImxComputeDeviceBehaviorPlugin,
			ImxRoomDeviceBehaviorPlugin,
			McrDeviceBehaviorPlugin,
			Mxa710DeviceBehaviorPlugin,
			Mxa901DeviceBehaviorPlugin,
			Mxa902DeviceBehaviorPlugin,
			Mxa920DeviceBehaviorPlugin,
			MxnAmpDeviceBehaviorPlugin,
			Mxn5cDeviceBehaviorPlugin,
			Mxw1xDeviceBehaviorPlugin,
			Mxw2xDeviceBehaviorPlugin,
			Mxw6xDeviceBehaviorPlugin,
			Mxw8xDeviceBehaviorPlugin,
			MxwApxDeviceBehaviorPlugin,
			Mxwapxd2DeviceBehaviorPlugin,
			MxwNdx4DeviceBehaviorPlugin,
			MxwNdx8DeviceBehaviorPlugin,
			P300DeviceBehaviorPlugin
		])
	],
	providers: [
		{
			provide: DeviceImageProvider,
			useExisting: DeviceImageBehaviorPluginService
		}
	]
})
export class CloudDeviceBehaviorPluginsModule {}
