import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { EMPTY, Observable, of, Subject, takeUntil } from 'rxjs';

import {
	NotificationsEndpointApiService,
	PostNotificationsRequest
} from '@shure/cloud/shared/data-access/notifications';
import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';
import { CloseTextOption, formatToUserSpecificTime, SnackbarService } from '@shure/cloud/shared/ui/components';

import { NotificationDetailsComponent } from '../notification-details/notification-details.component';

@Component({
	selector: 'sh-notifications-popup',
	templateUrl: './notifications-popup.component.html',
	styleUrls: ['./notifications-popup.component.scss']
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {
	public application: string;
	public notifications$: Observable<NotificationStoreData[] | null> = EMPTY;
	public currentDate = new Date();
	public readonly destroy$ = new Subject<void>();
	public notificationsData: NotificationStoreData[] = [];

	constructor(
		public notificationsStore: NotificationsStore,
		public notificationService: NotificationsEndpointApiService,
		public dialogRef: MatDialog,
		public router: Router,
		public cdr: ChangeDetectorRef,
		private translocoService: TranslocoService,
		private snackBarService: SnackbarService,
		@Inject(MAT_DIALOG_DATA) public data: { application?: string }
	) {
		this.application = <string>data?.application;
	}

	/**
	 * Initializes the component and subscribe to the notifications store.
	 */
	public ngOnInit(): void {
		// Subscribe to the notifications data
		this.notificationsStore.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
			this.notificationsData = notifications;
			const unreadNotifications = notifications.filter((n) => !n.readAt && !n.archivedAt);
			this.notifications$ = of(
				unreadNotifications.map((item) => ({
					...item,
					body: item.body?.replace(/<[^>]*(>|$)| |‌|»|«|>/g, ' '),
					sentAt: item.sentAt ? formatToUserSpecificTime(item.sentAt) : ''
				}))
			);
			this.cdr.detectChanges();
		});
	}

	/**
	 * Closes all dialogs and navigates to the notifications details page.
	 */
	public notificationDetails(): void {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('notifications-details');
	}

	/**
	 * Returns the current date in UTC format as a string.
	 */
	public getCurrentDateInUTC(): string {
		const currentDate = new Date();
		const currentdateFormat = new Date(currentDate.getTime() + 10000);
		return currentdateFormat.toISOString().slice(0, 19) + 'Z';
	}

	/**
	 * Opens a dialog with details of the selected notification.
	 * @param notification The notification to display details for
	 */
	public async openPopup(notification: NotificationStoreData): Promise<void> {
		this.dialogRef.closeAll();
		const selectedNotification = this.notificationsData.find((item) => item.id === notification.id);
		// Define the notificationId based on the type of notification passed

		this.dialogRef.open(NotificationDetailsComponent, {
			width: '500px',
			data: { notificationData: selectedNotification, isListPage: false }
		});
	}

	/**
	 * Marks all unread notifications as read.
	 */
	public clearAll(): void {
		let displayedNotifications: NotificationStoreData[] = [];
		this.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
			displayedNotifications = <NotificationStoreData[]>(<unknown>notifications);
		});
		const displayedNotificationsIds = displayedNotifications.map(
			(notification: NotificationStoreData) => notification?.id
		);
		const requestData = {
			body: displayedNotificationsIds.map((notificationId) => ({
				id: notificationId,
				isRead: true
			}))
		};
		const readNotifications = displayedNotificationsIds.map((notificationId) => ({
			id: notificationId,
			readAt: this.getCurrentDateInUTC()
		}));
		this.updateNotifications(requestData, readNotifications);
	}

	public markAsRead(notificationData: NotificationStoreData): void {
		const requestData = {
			body: [
				{
					id: notificationData.id,
					isRead: true
				}
			]
		};
		const readNotification = [
			{
				id: notificationData.id,
				readAt: this.getCurrentDateInUTC()
			}
		];
		this.updateNotifications(requestData, readNotification);
	}

	/**
	 * Post API call for updating notifications status.
	 */
	public updateNotifications(
		requestData: { body: PostNotificationsRequest },
		updatedNotifications: Array<Pick<NotificationStoreData, 'id'> & Partial<NotificationStoreData>>
	): void {
		this.notificationService
			.postNotifications(requestData)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.notificationsStore.prependOrPatchNotifications(updatedNotifications);
				},
				error: () => {
					this.snackBarService.open(
						this.translocoService.translate('cloud.shared.notification.unable-to-mark-as-read'),
						CloseTextOption.Dismiss
					);
				}
			});
	}

	/**
	 * Closes all open dialogs.
	 */
	public closedialog(): void {
		this.dialogRef.closeAll();
	}

	/**
	 * Closes all dialogs and navigates to the notifications list page.
	 */
	public notificationsList(): void {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('/notifications-list');
	}

	/**
	 * Performs cleanup when the component is destroyed.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
