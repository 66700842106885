const SHOW_RESEND_TIMEOUT = 30000;
const WARNING_TIMEOUT = 30000;
const CHALLENGE_TIMEOUT = 300000;
const MS_PER_SEC = 1000;
const UNIVERSAL_LINK_POST_DELAY = 500;
const CANCEL_POLLING_ACTION = 'authenticatorChallenge-cancel';
const AUTHENTICATOR_CANCEL_ACTION = 'currentAuthenticator-cancel';
const WIDGET_FOOTER_CLASS = 'siw-main-footer';
const FASTPASS_FALLBACK_SPINNER_TIMEOUT = 4000;
const IDENTIFIER_FLOW = 'IDENTIFIER';
const OV_UV_ENABLE_BIOMETRICS_FASTPASS_DESKTOP = 'oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.desktop';
const OV_UV_ENABLE_BIOMETRICS_FASTPASS_MOBILE = 'oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.mobile';
const AUTHENTICATOR_ALLOWED_FOR_OPTIONS = {
  ANY: 'any',
  SSO: 'sso',
  RECOVERY: 'recovery'
};
const REQUEST_PARAM_AUTHENTICATION_CANCEL_REASON = 'reason';
const LOOPBACK_RESPONSE_STATUS_CODE = 'statusCode';
const AUTHENTICATION_CANCEL_REASONS = {
  LOOPBACK_FAILURE: 'OV_UNREACHABLE_BY_LOOPBACK',
  OV_ERROR: 'OV_RETURNED_ERROR',
  USER_CANCELED: 'USER_CANCELED'
};

export { AUTHENTICATION_CANCEL_REASONS, AUTHENTICATOR_ALLOWED_FOR_OPTIONS, AUTHENTICATOR_CANCEL_ACTION, CANCEL_POLLING_ACTION, CHALLENGE_TIMEOUT, FASTPASS_FALLBACK_SPINNER_TIMEOUT, IDENTIFIER_FLOW, LOOPBACK_RESPONSE_STATUS_CODE, MS_PER_SEC, OV_UV_ENABLE_BIOMETRICS_FASTPASS_DESKTOP, OV_UV_ENABLE_BIOMETRICS_FASTPASS_MOBILE, REQUEST_PARAM_AUTHENTICATION_CANCEL_REASON, SHOW_RESEND_TIMEOUT, UNIVERSAL_LINK_POST_DELAY, WARNING_TIMEOUT, WIDGET_FOOTER_CLASS };

