import { loc } from '../../../../../packages/@okta/courage-dist/esm/src/CourageForSigninWidget.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/framework/Model.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/vendor/lib/backbone.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/util/jquery-wrapper.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/util/underscore-wrapper.js';
import '../../../../../packages/@okta/courage-dist/esm/lib/handlebars/dist/cjs/handlebars.runtime.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/models/Model.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/models/BaseModel.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/framework/View.js';
import '../../../../../packages/@okta/courage-dist/esm/src/courage/views/Backbone.ListView.js';
import EnrollAuthenticatorPasswordView from './EnrollAuthenticatorPasswordView.js';

const Body = EnrollAuthenticatorPasswordView.prototype.Body.extend({
  className: 'password-authenticator',
  title: function () {
    const title = this.options.settings.get('brandName') ? loc('password.reset.title.specific', 'login', [this.options.settings.get('brandName')]) : loc('password.reset.title.generic', 'login');
    return title;
  },
  save: function () {
    return loc('password.reset', 'login');
  }
});
var ResetAuthenticatorPasswordView = EnrollAuthenticatorPasswordView.extend({
  Body: Body
});

export { ResetAuthenticatorPasswordView as default };

