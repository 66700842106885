import { Directive, Input, forwardRef, Renderer2, ElementRef } from '@angular/core';

import { PermissionsActionDirective } from './permissions-action';

@Directive({
	selector: '[shPermissionsAddClass]',
	providers: [
		{
			provide: PermissionsActionDirective,
			useExisting: forwardRef(() => PermissionsActionAddClassDirective)
		}
	]
})
export class PermissionsActionAddClassDirective extends PermissionsActionDirective {
	@Input('shPermissionsAddClass') private className!: string;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) {
		super();
	}

	public execute(): void {
		this.renderer.addClass(this.el.nativeElement, this.className);
	}

	public getClassName(): string {
		return this.className;
	}
}
