import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { catchError, EMPTY, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';

import {
	GetNotificationResponse,
	NotificationsEndpointApiService
} from '@shure/cloud/shared/data-access/notifications';
import { NotificationsStore, NotificationStoreData } from '@shure/cloud/shared/notifications-store';
import { CloseTextOption, formatToUserSpecificTime, SnackbarService } from '@shure/cloud/shared/ui/components';

import { NotificationDetailsComponent } from '../notification-details/notification-details.component';

@Component({
	selector: 'sh-notifications-popup',
	templateUrl: './notifications-popup.component.html',
	styleUrls: ['./notifications-popup.component.scss']
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {
	public application: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public notifications$: Observable<any[] | null> = EMPTY;
	public currentDate = new Date();
	public readonly destroy$ = new Subject<void>();

	constructor(
		public notificationsStore: NotificationsStore,
		public notificationService: NotificationsEndpointApiService,
		public dialogRef: MatDialog,
		public router: Router,
		public cdr: ChangeDetectorRef,
		private translocoService: TranslocoService,
		private snackBarService: SnackbarService,
		@Inject(MAT_DIALOG_DATA) public data: { application?: string }
	) {
		this.application = <string>data?.application;
	}

	/**
	 * Initializes the component and subscribe to the notifications store.
	 */
	public ngOnInit(): void {
		// Subscribe to the notifications data
		this.notificationsStore.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
			const unreadNotifications = notifications.filter((n) => !n.readAt && !n.archivedAt);
			this.notifications$ = of(
				<GetNotificationResponse[]>(<unknown>unreadNotifications.map((item) => ({
					...item,
					body: item.body?.replace(/<[^>]*(>|$)| |‌|»|«|>/g, ' '),
					sentAt: item.sentAt ? formatToUserSpecificTime(item.sentAt) : ''
				})))
			);
			this.cdr.detectChanges();
		});
	}

	/**
	 * Closes all dialogs and navigates to the notifications details page.
	 */
	public notificationDetails(): void {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('notifications-details');
	}

	/**
	 * Returns the current date in UTC format as a string.
	 */
	public getCurrentDateInUTC(): string {
		const currentDate = new Date();
		const currentdateFormat = new Date(currentDate.getTime() + 10000);
		return currentdateFormat.toISOString().slice(0, 19) + 'Z';
	}

	/**
	 * Opens a dialog with details of the selected notification.
	 * @param notification The notification to display details for
	 */
	public async openPopup(notification: NotificationStoreData | GetNotificationResponse): Promise<void> {
		this.dialogRef.closeAll();
		// Define the notificationId based on the type of notification passed
		let notificationId: string;

		// Check if 'items' exists, implying it's a GetNotificationResponse
		if ('items' in notification && notification.items && notification.items.length > 0) {
			notificationId = notification.items[0].id;
		} else if ('id' in notification) {
			notificationId = notification.id;
			notification.isRead = true;
		} else {
			return;
		}

		this.dialogRef.open(NotificationDetailsComponent, {
			width: '500px',
			data: { notificationId: notificationId, isListPage: false, shouldMarkAsReadOnOpen: true }
		});
	}

	/**
	 * Marks all unread notifications as read.
	 */
	public clearAll(): void {
		let displayedNotifications: NotificationStoreData[] = [];
		this.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
			displayedNotifications = <NotificationStoreData[]>(<unknown>notifications);
		});
		const displayedNotificationsIds = displayedNotifications.map(
			(notification: NotificationStoreData) => notification?.id
		);
		const requestData = {
			body: displayedNotificationsIds.map((notificationId) => ({
				id: notificationId,
				isRead: true
			}))
		};
		const readNotifications = displayedNotificationsIds.map((notificationId) => ({
			id: notificationId,
			readAt: this.getCurrentDateInUTC()
		}));
		this.notificationService
			.postNotifications(requestData)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.notificationsStore.prependOrPatchNotifications(readNotifications);
				},
				error: () => {
					this.snackBarService.open(
						this.translocoService.translate('cloud.shared.notification.unable-to-mark-as-read'),
						CloseTextOption.Dismiss
					);
				}
			});
	}

	/**
	 * Closes all open dialogs.
	 */
	public closedialog(): void {
		this.dialogRef.closeAll();
	}

	/**
	 * Marks specified notifications as read.
	 * @param notificationIds Array of notification IDs to mark as read
	 */
	public markedAsRead(notificationIds: string[]): void {
		const requestData = {
			body: notificationIds.map((item) => ({
				id: item,
				isRead: true
			}))
		};

		this.notificationService
			.postNotifications(requestData)
			.pipe(
				take(1),
				switchMap(() => this.notifications$),
				take(1),
				map((notifications) => {
					if (!notifications) {
						return null;
					}
					const updatedNotification = notifications.find(
						(n) => n.items && n.items.length > 0 && notificationIds.includes(n.items[0].id)
					);

					if (updatedNotification && updatedNotification.items && updatedNotification.items.length > 0) {
						updatedNotification.items[0].readAt = this.getCurrentDateInUTC();
					}
					return notifications;
				}),
				catchError((_error) => {
					return of(null);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((updatedNotifications) => {
				if (updatedNotifications !== null) {
					this.notificationsStore.prependOrPatchNotifications(
						<NotificationStoreData[]>(<unknown>updatedNotifications)
					);
				}
			});
	}

	/**
	 * Closes all dialogs and navigates to the notifications list page.
	 */
	public notificationsList(): void {
		this.dialogRef.closeAll();
		this.router.navigateByUrl('/notifications-list');
	}

	/**
	 * Performs cleanup when the component is destroyed.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
