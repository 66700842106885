import { Component, DestroyRef, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { VerificationStatus } from '@shure/cloud/shared/services/organizations-store-service';
import { OrganizationDetails } from '@shure/cloud/shared/switch-organization/data-access';
import { generateColorFromName } from '@shure/cloud/shared/ui/components';
import { CloseTextOption, SnackbarService } from '@shure/cloud/shared/ui/components';

import { OrganizationList } from '../../models/switch-organization.model';
import { SwitchOrganizationService } from '../../services/switch-organization.service';

@Component({
	selector: 'sh-change-organization-dialog',
	templateUrl: './change-organization-dialog.component.html',
	styleUrl: './change-organization-dialog.component.scss'
})
export class ChangeOrganizationDialogComponent implements OnInit {
	public organizationsResponseList!: OrganizationList[];
	public organizationsList!: OrganizationList[];
	public defaultOrgId!: string;
	public selectedOrganization!: OrganizationDetails;
	public readonly toggleLoader = signal(true);
	public verificationStatus = VerificationStatus;
	constructor(
		private switchOrganizationService: SwitchOrganizationService,
		public dialogRef: MatDialogRef<ChangeOrganizationDialogComponent>,
		private readonly destroyRef: DestroyRef,
		private snackBarService: SnackbarService,
		private translocoService: TranslocoService,
		private readonly oktaIntfService: OktaInterfaceService
	) {}
	public ngOnInit(): void {
		this.oktaIntfService
			.getDefaultOrgId$()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((val: string) => {
				this.defaultOrgId = val;
			});
		this.getAllMyOrganizations();
	}

	public getAllMyOrganizations(): void {
		this.switchOrganizationService
			.getAllMyOrganizations()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (organziationListResp) => {
					this.organizationsList = organziationListResp.body?.body?.items || [];
					this.moveCurrentOrgToTop();
					this.organizationsList = this.organizationsList.map((org) => {
						return {
							...org,
							companyName: org.companyName || '--',
							profileColor: generateColorFromName(org.name ?? ''),
							initials: this.generateInitials(org.name ?? '')
						};
					});
					this.organizationsResponseList = JSON.parse(JSON.stringify(this.organizationsList));
				},
				error: (error) => {
					const errorMessage = error.error;
					this.snackBarService.open(
						this.translocoService.translate(
							errorMessage?.i18nKey
								? 'cloud.shared.error-labels.' + errorMessage.i18nKey
								: errorMessage.message
						),
						CloseTextOption.Ok
					);
				},
				complete: () => {
					this.toggleLoader.set(false);
				}
			});
	}

	/**
	 * Function to move the default organization to the top of the organizations list.
	 */
	public moveCurrentOrgToTop(): void {
		const defaultOrgIndex = this.organizationsList.findIndex(
			(organization) => organization.id === this.defaultOrgId
		);
		if (defaultOrgIndex !== -1) {
			const defaultOrganizationDetails = this.organizationsList.splice(defaultOrgIndex, 1);
			this.organizationsList.unshift(defaultOrganizationDetails[0]);
		}
	}
	/**
	 * This function will close the change organization dialog and open the create organization dialog.
	 */
	public openCreateOrganization(): void {
		this.dialogRef.close();
		this.switchOrganizationService.openCreateDialog();
	}

	/**
	 * This will close the dialog when user clicks on cancel
	 */
	public closeDialog(): void {
		this.dialogRef.close();
	}

	/**
	 * This function will return the selected org data from the organization list.
	 */
	public selectOrganization(event: Event, org: OrganizationDetails): void {
		if (org && this.defaultOrgId !== org.id) {
			this.selectedOrganization = org;
		}
	}

	/**
	 * This function will filter the organizations list
	 * It is called for every single list item  to check if the search string is present in the list
	 * @param searchKey - search text with which the list is filtered
	 */
	public applyFilter(searchKey: string): void {
		const filterValue = searchKey.trim().toLowerCase();
		this.organizationsList = this.organizationsResponseList.filter(
			(org) =>
				org?.name?.toLowerCase().includes(filterValue) || org?.companyName?.toLowerCase().includes(filterValue)
		);
	}

	/**
	 * This function will return the initials for the organization
	 * @param orgName - organization name as a string
	 */
	public generateInitials(orgName: string): string {
		return orgName.split(' ')[0].charAt(0).toUpperCase() + (orgName.split(' ')[1]?.charAt(0).toUpperCase() || '');
	}

	/**
	 * Function to change the selected organization and reload the window.
	 */
	public changeOrganization(): void {
		if (this.selectedOrganization.id) {
			this.switchOrganizationService.changeOrganization(this.selectedOrganization.id);
		}
	}
}
