var signout = "Sign Out";
var signin = "Sign In";
var signup = "Sign Up";
var remember = "Remember me";
var rememberDevice = "Trust this device";
var autoPush = "Send push automatically";
var unlockaccount = "Unlock account?";
var haveaccount = "Already have an account?";
var needhelp = "Need help signing in?";
var forgotpassword = "Forgot password?";
var help = "Help";
var retry = "Retry";
var iphone = "iPhone";
var android = "Android";
var goback = "Back to sign in";
var closeWindow = "You can close this window";
var minutes = "{0} minutes";
var hours = "{0} hours";
var days = "{0} days";
var tagsNotAllowed = "The field must not contain HTML tags";
var E0000010 = "Server is unable to respond at the moment.";
var login = {
	signout: signout,
	signin: signin,
	signup: signup,
	remember: remember,
	rememberDevice: rememberDevice,
	"rememberDevice.timebased": "Do not challenge me on this device for the next {0}",
	"rememberDevice.devicebased": "Do not challenge me on this device again",
	autoPush: autoPush,
	unlockaccount: unlockaccount,
	haveaccount: haveaccount,
	needhelp: needhelp,
	forgotpassword: forgotpassword,
	help: help,
	retry: retry,
	iphone: iphone,
	android: android,
	goback: goback,
	closeWindow: closeWindow,
	"country.label": "Country",
	"country.option.label": "{0} ({1})",
	"phone.extention.label": "Extension",
	"select.default_value": "Select an option",
	"select.filter.no_match": "No results found for \"{0}\"",
	"sensitive.input.show": "Show",
	"sensitive.input.hide": "Hide",
	"processing.alt.text": "Processing...",
	"logo.default.alt.text": "Logo",
	"identifier.icon.alt.text": "User",
	"icon.title.application": "Application",
	"icon.title.browser": "Browser",
	"icon.title.mobileBrowser": "Mobile browser",
	"icon.title.location": "Location",
	"icon.title.mobileDevice": "Mobile Device",
	"skip.to.main.content": "Skip to main content",
	"minutes.oneMinute": "minute",
	minutes: minutes,
	hours: hours,
	days: days,
	"error.config": "There was a configuration error",
	"error.required.authParams": "Missing parameters for the configured authentication scheme - \"OAUTH2\"",
	"error.required.baseUrl": "\"baseUrl\" is a required widget parameter",
	"error.required.success": "A success handler is required",
	"error.required.el": "\"el\" is a required widget parameter",
	"error.invalid.colors.brand": "\"colors.brand\" must be in six-digit hex format",
	"error.unsupported.browser": "Unsupported browser",
	"error.unsupported.cors": "Unsupported browser - missing CORS support",
	"error.unsupported.localStorage": "Unsupported browser - missing localStorage support",
	"error.enabled.cors": "There was an error sending the request - have you enabled CORS?",
	"error.expired.session": "Your session has expired. Please try to sign in again.",
	"error.mfa.only.expired.session": "Unable to authenticate at this time.",
	"error.auth.lockedOut": "Your account is locked. Please contact your administrator.",
	"error.oauth.idToken": "There was a problem generating the id_token for the user. Please try again.",
	"error.network.connection": "Unable to connect to the server. Please check your network connection.",
	"error.username.required": "Please enter a username",
	"error.password.required": "Please enter a password",
	"error.mfa.required": "The current sign on policy requires this client to redirect for re-authentication or MFA. Please contact your administrator.",
	"error.unsupported.response": "There was an unsupported response from server.",
	"error.jit_failure": "Unable to sign in, please try another sign in method.",
	"error.unsynced.clock": "Unable to sign in. Please check that your device's clock is accurate and try again.",
	"errors.E0000004": "Unable to sign in",
	"errors.E0000069": "Your account is locked because of too many authentication attempts.",
	"errors.E0000047": "You exceeded the maximum number of requests. Try again in a while.",
	"errors.E0000079": "The operation is not allowed. Please refresh the page to proceed.",
	"errors.E0000001": "Api validation failed: {0}",
	"errors.E0000002": "The request was not valid: {0}",
	"errors.E0000003": "The request body was not well-formed.",
	"errors.E0000005": "Invalid session",
	"errors.E0000006": "You do not have permission to perform the requested action",
	"errors.E0000007": "Not found: {0}",
	"errors.E0000008": "The requested path was not found",
	"errors.E0000009": "Internal Server Error",
	"errors.E0000010": "Service is in read only mode",
	"errors.E0000011": "Invalid token provided",
	"errors.E0000012": "Unsupported media type",
	"errors.E0000013": "Invalid client app id",
	"errors.E0000015": "You do not have permission to access the feature you are requesting",
	"errors.E0000016": "Activation failed because the user is already active",
	"errors.E0000017": "Password reset failed",
	"errors.E0000018": "Bad request.  Accept and/or Content-Type headers are likely not set.",
	"errors.E0000019": "Bad request.  Accept and/or Content-Type headers likely do not match supported values.",
	"errors.E0000020": "Bad request.",
	"errors.E0000021": "Bad request.  Accept and/or Content-Type headers likely do not match supported values.",
	"errors.E0000022": "The endpoint does not support the provided HTTP method",
	"errors.E0000023": "Operation failed because user profile is mastered under another system",
	"errors.E0000024": "Bad request.  This operation on app metadata is not yet supported.",
	"errors.E0000025": "App version assignment failed.",
	"errors.E0000026": "This endpoint has been deprecated.",
	"errors.E0000027": "Group push bad request : {0}",
	"errors.E0000028": "The request is missing a required parameter.",
	"errors.E0000029": "Invalid paging request.",
	"errors.E0000030": "Bad request. Invalid date. Dates must be of the form yyyy-MM-dd''T''HH:mm:ss.SSSZZ, e.g. 2013-01-01T12:00:00.000-07:00.",
	"errors.E0000031": "Invalid search criteria.",
	"errors.E0000032": "Unlock is not allowed for this user.",
	"errors.E0000033": "Bad request. Can't specify a search query and filter in the same request.",
	"errors.E0000034": "Forgot password not allowed on specified user.",
	"errors.E0000035": "Change password not allowed on specified user.",
	"errors.E0000036": "Change recovery question not allowed on specified user.",
	"errors.E0000037": "Type mismatch exception.",
	"errors.E0000038": "This operation is not allowed in the user''s current status.",
	"errors.E0000039": "Operation on application settings failed.",
	"errors.E0000040": "Application label must not be the same as an existing application label.",
	"errors.E0000041": "Credentials should not be set on this resource based on the scheme.",
	"errors.E0000042": "Setting the error page redirect URL failed.",
	"errors.E0000043": "Self service application assignment is not enabled.",
	"errors.E0000044": "Self service application assignment is not supported.",
	"errors.E0000045": "Field mapping bad request.",
	"errors.E0000046": "Deactivate application for user forbidden.",
	"errors.E0000048": "Entity not found exception.",
	"errors.E0000049": "Invalid SCIM data from SCIM implementation.",
	"errors.E0000050": "Invalid SCIM data from client.",
	"errors.E0000051": "No response from SCIM implementation.",
	"errors.E0000052": "Endpoint not implemented.",
	"errors.E0000053": "Invalid SCIM filter.",
	"errors.E0000054": "Invalid pagination properties.",
	"errors.E0000055": "Duplicate group.",
	"errors.E0000056": "Delete application forbidden.",
	"errors.E0000057": "Access to this application is denied due to a policy.",
	"errors.E0000058": "Access to this application requires MFA: {0}",
	"errors.E0000059": "The connector configuration could not be tested. Make sure that the URL, Authentication Parameters are correct and that there is an implementation available at the URL provided.",
	"errors.E0000060": "Unsupported operation.",
	"errors.E0000061": "Tab error: {0}",
	"errors.E0000062": "The specified user is already assigned to the application.",
	"errors.E0000063": "Invalid combination of parameters specified.",
	"errors.E0000064": "Password is expired and must be changed.",
	"errors.E0000065": "Internal error processing app metadata.",
	"errors.E0000066": "APNS is not configured, contact your admin",
	"errors.E0000067": "Factors Service Error.",
	"errors.E0000070": "Waiting for ACK",
	"errors.E0000071": "Unsupported OS Version: {0}",
	"errors.E0000072": "MIM policy settings have disallowed enrollment for this user",
	"errors.E0000073": "User rejected authentication",
	"errors.E0000074": "Factor Service Error",
	"errors.E0000075": "Cannot modify the {0} attribute because it has a field mapping and profile push is enabled.",
	"errors.E0000076": "Cannot modify the app user because it is mastered by an external app.",
	"errors.E0000077": "Cannot modify the {0} attribute because it is read-only.",
	"errors.E0000078": "Cannot modify the {0} attribute because it is immutable.",
	"errors.E0000081": "Cannot modify the {0} attribute because it is a reserved attribute for this application.",
	"errors.E0000082": "Each code can only be used once. Please wait for a new code and try again.",
	"errors.E0000083": "PassCode is valid but exceeded time window.",
	"errors.E0000084": "App evaluation error.",
	"errors.E0000085": "You do not have permission to access your account at this time.",
	"errors.E0000086": "This policy cannot be activated at this time.",
	"errors.E0000087": "The recovery question answer did not match our records.",
	"errors.E0000090": "The role specified is already assigned to the user.",
	"errors.E0000091": "The provided role type was not the same as required role type.",
	"errors.E0000092": "Access to this application requires re-authentication: {0}",
	"errors.E0000093": "Target count limit exceeded",
	"errors.E0000094": "The provided filter is unsupported.",
	"errors.E0000095": "Recovery not allowed for unknown user.",
	"errors.E0000096": "This certificate has already been uploaded with kid={0}.",
	"errors.E0000097": "There is no verified phone number on file.",
	"errors.E0000098": "This phone number is invalid.",
	"errors.E0000099": "Only numbers located in US and Canada are allowed.  Contact your administrator if this is a problem.",
	"errors.E0000100": "Unable to perform search query.",
	"errors.E0000101": "Upload failed because of a problem with your ipa file, {0}",
	"errors.E0000102": "YubiKey cannot be deleted while assigned to an user. Please deactivate YubiKey using reset MFA and try again",
	"errors.E0000103": "Action on device already in queue or in progress",
	"errors.E0000104": "Device is already locked and cannot be locked again",
	"errors.E0000105": "You have accessed an account recovery link that has expired or been previously used.",
	"errors.E0000106": "Wait for token to change, then enter the new tokencode.",
	"errors.E0000107": "The entity is not in the expected state for the requested transition.",
	"errors.E0000109": "An SMS message was recently sent. Please wait 30 seconds before trying again.",
	"errors.E0000110": "You have accessed a link that has expired or has been previously used.",
	"errors.E0000111": "Cannot modify the {0} object because it is read-only.",
	"errors.E0000112": "Cannot update this user because they are still being activated. Please try again in a few minutes.",
	"errors.E0000113": "{0}.",
	"errors.E0000114": "A user with this login already exists in the current organization.",
	"errors.E0000115": "Upload failed, {0}",
	"errors.E0000116": "{0}",
	"errors.E0000119": "Your account is locked. Please contact your administrator.",
	"errors.E0000124": "Could not create user. To create a user and expire their password immediately, a password must be specified",
	"errors.E0000125": "Could not create user. To create a user and expire their password immediately, \"activate\" must be true",
	"errors.E0000133": "A phone call was recently made. Please wait 30 seconds before trying again.",
	"errors.E0000207": "The username and/or the password you entered is incorrect. Please try again.",
	"oform.next": "Next",
	"oform.verify": "Verify",
	"oform.send": "Send",
	"oform.back": "Back",
	"oform.title.authenticate": "Authenticate",
	"oform.save": "Save",
	"oform.cancel": "Cancel",
	"oform.edit": "Edit",
	"oform.previous": "Previous",
	"oform.error.icon.ariaLabel": "Error",
	"oform.errorbanner.title": "We found some errors. Please review the form and make corrections.",
	"oform.errormsg.title": "Please review the form to correct the following errors:",
	"oform.error.unexpected": "There was an unexpected internal error. Please try again.",
	"oform.error.webfinger": "Unable to determine user identification method. Please contact your administrator for assistance.",
	"oform.selectInput.country": "{0} selected",
	"model.validation.field.blank": "This field cannot be left blank",
	"model.validation.field.wrong.type": "This field is of the wrong type",
	"model.validation.field.invalid": "This field has an invalid value",
	"model.validation.field.value.not.allowed": "This field value is not allowed",
	"model.validation.field.array.minItems": "This array does not have enough items",
	"model.validation.field.array.maxItems": "This array contains too many items",
	"model.validation.field.array.unique": "This array can only have unique values",
	"model.validation.field.string.minLength": "This field cannot be less than the minimum required characters",
	"model.validation.field.string.maxLength": "This field cannot exceed the maximum allowed characters",
	"model.validation.field.invalid.format.email": "This value is not a valid email address",
	"model.validation.field.invalid.format.uri": "This value is not a valid URI",
	"model.validation.field.invalid.format.ipv4": "This value is not a valid IPv4 address",
	"model.validation.field.invalid.format.hostname": "This value is not a valid hostname",
	"model.validation.field.username": "Please check your username",
	"schema.validation.field.value.must.string": "Value must be a string",
	"schema.validation.field.value.must.number": "Value must be a number",
	"schema.validation.field.value.must.integer": "Value must be an integer",
	"schema.validation.field.value.must.object": "Value must be an object",
	"universal-directory.profiles.attribute.source.oktamastered": "Inherit from Okta",
	"universal-directory.profiles.attribute.source.override": "Override profile source",
	"universal-directory.profiles.attribute.form.union.disable.display": "Combine values across groups",
	"universal-directory.profiles.attribute.source.inherit": "Inherit from profile source",
	"universal-directory.profiles.attribute.enduser.permission.readonly": "Read Only",
	"universal-directory.profiles.attribute.enduser.permission.hide": "Hide",
	"universal-directory.profiles.attribute.enduser.permission.readwrite": "Read-Write",
	"universal-directory.profiles.attribute.form.union.enable.display": "Use Group Priority",
	"factor.totpSoft.oktaVerify": "Okta Verify",
	"factor.totpSoft.googleAuthenticator": "Google Authenticator",
	"factor.totpSoft.description": "Enter single-use code from the mobile app.",
	"factor.totpHard.rsaSecurId": "RSA SecurID",
	"factor.totpHard.symantecVip": "Symantec VIP",
	"factor.totpHard.description": "Enter a single-use code from a hardware token.",
	"factor.totpHard.yubikey": "YubiKey",
	"factor.totpHard.yubikey.description": "Insert your YubiKey and tap it to get a verification code.",
	"factor.totpHard.yubikey.placeholder": "Click here, then tap your YubiKey",
	"factor.oktaVerifyPush": "Okta Verify",
	"factor.push.description": "Use a push notification sent to the mobile app.",
	"factor.duo": "Duo Security",
	"factor.duo.description": "Use Push Notification, SMS, or Voice call to authenticate.",
	"factor.sms": "SMS Authentication",
	"factor.sms.description": "Enter a single-use code sent to your mobile phone.",
	"factor.sms.time.warning": "Haven't received an SMS? To try again, click <$1>Re-send code</$1>.",
	"factor.call": "Voice Call Authentication",
	"factor.call.description": "Use a phone to authenticate by following voice instructions.",
	"factor.call.time.warning": "Haven't received a voice call? To try again, click <$1>Redial</$1>.",
	"factor.securityQuestion": "Security Question",
	"factor.securityQuestion.description": "Use the answer to a security question to authenticate.",
	"factor.windowsHello": "Windows Hello",
	"factor.windowsHello.signin.description": "Sign in to Okta using Windows Hello.",
	"factor.windowsHello.signin.description.generic": "Sign in using Windows Hello.",
	"factor.windowsHello.signin.description.specific": "Sign in to {0} using Windows Hello.",
	"factor.u2f": "Security Key (U2F)",
	"factor.u2f.description": "Use a Universal 2nd Factor (U2F) security key to sign on to Okta.",
	"factor.u2f.description.generic": "Use a Universal 2nd Factor (U2F) security key to sign in.",
	"factor.u2f.description.specific": "Use a Universal 2nd Factor (U2F) security key to sign in to {0}.",
	"factor.email": "Email Authentication",
	"factor.email.description": "Enter a verification code sent to your email.",
	"factor.password": "Password",
	"factor.customFactor.description": "Redirect to a third party MFA provider to sign in to Okta.",
	"factor.customFactor.description.generic": "Redirect to a third party MFA provider to sign in.",
	"factor.customFactor.description.specific": "Redirect to a third party MFA provider to sign in to {0}.",
	"factor.webauthn.biometric": "Security Key or Biometric Authenticator",
	"factor.webauthn.biometric.description": "Use a security key (USB or bluetooth) or a biometric authenticator (Windows Hello, Touch ID, etc.)",
	"factor.hotp.description": "Enter a single-use code from an authenticator.",
	"mfa.challenge.verify": "Verify",
	"mfa.challenge.answer.placeholder": "Answer",
	"mfa.challenge.answer.tooltip": "Answer",
	"mfa.challenge.answer.showAnswer": "Show",
	"mfa.challenge.answer.hideAnswer": "Hide",
	"mfa.challenge.enterCode.placeholder": "Enter Code",
	"mfa.challenge.enterCode.tooltip": "Enter Code",
	"mfa.challenge.password.placeholder": "Password",
	"mfa.backToFactors": "Back to factor list",
	"mfa.enroll": "Enroll",
	"mfa.setupOptions": "Setup Options",
	"mfa.country": "Country/region",
	"mfa.phoneNumber.placeholder": "Phone number",
	"mfa.phoneNumber.ext.placeholder": "Extension",
	"mfa.sendCode": "Send code",
	"mfa.sent": "Sent",
	"mfa.resendCode": "Re-send code",
	"mfa.call": "Call",
	"mfa.calling": "Calling",
	"mfa.redial": "Redial",
	"mfa.sendEmail": "Send email",
	"mfa.resendEmail": "Re-send email",
	"mfa.scanBarcode": "Scan QR Code",
	"mfa.altQrCode": "QR code",
	"mfa.noAccessToEmail": "Can't access email",
	"password.reset": "Reset Password",
	"password.oldPassword.placeholder": "Old password",
	"password.oldPassword.tooltip": "Old password",
	"password.newPassword.placeholder": "New password",
	"password.newPassword.tooltip": "New password",
	"password.confirmPassword.placeholder": "Repeat password",
	"password.confirmPassword.tooltip": "Repeat password",
	"password.error.match": "New passwords must match",
	"password.enroll.error.match": "Passwords must match",
	"recovery.sms.hint": "SMS can only be used if a mobile phone number has been configured.",
	"recovery.mobile.hint": "{0} can only be used if a mobile phone number has been configured.",
	"recovery.sms": "SMS",
	"recovery.call": "Voice Call",
	"recovery.smsOrCall": "SMS or Voice Call",
	"verify.choices.description": "Verify with one of the following factors.",
	"email.button.send": "Send me the code",
	"email.button.resend": "Send again",
	"email.code.label": "Verification code",
	"email.code.not.received": "Haven't received an email?",
	"email.enroll.title": "Set up Email Authentication",
	"email.enroll.description": "Send a verification code to your registered email.",
	"email.enroll.enterCode": "Enter code",
	"email.link.terminal.msg": "To finish signing in, return to the screen where you requested the email link.",
	"email.mfa.title": "Verify with Email Authentication",
	"email.mfa.description": "Send a verification code to {0}.",
	"email.mfa.email.sent.description": "A verification code was sent to {0}. Check your email and enter the code below.",
	"email.mfa.email.sent.description.sentText": "A verification code was sent to",
	"email.mfa.email.sent.description.emailCodeText": "Check your email and enter the code below.",
	"enroll.choices.title": "Set up multifactor authentication",
	"enroll.choices.description": "Your company requires multifactor authentication to add an additional layer of security when signing in to your Okta account",
	"enroll.choices.description.generic": "Your company requires multifactor authentication to add an additional layer of security when signing in to your account",
	"enroll.choices.description.specific": "Your company requires multifactor authentication to add an additional layer of security when signing in to your {0} account",
	"enroll.choices.description.gracePeriod.bold": "Your company recommends setting up additional factors for authentication. Set up will be required in: <b>{0} day(s)</b>.",
	"enroll.choices.description.gracePeriod.oneDay.bold": "Your company recommends setting up additional factors for authentication. Set up will be required in: <b>less than 1 day</b>.",
	"enroll.choices.optional": "You can configure any additional optional factor or click finish",
	"enroll.choices.list.setup": "Setup required",
	"enroll.choices.list.enrolled": "Enrolled factors",
	"enroll.choices.list.optional": "Additional optional factors",
	"enroll.choices.setup": "Setup",
	"enroll.choices.setup.another": "Set up another",
	"enroll.choices.submit.finish": "Finish",
	"enroll.choices.submit.configure": "Configure factor",
	"enroll.choices.submit.next": "Configure next factor",
	"enroll.choices.cardinality.setup": "({0} set up)",
	"enroll.choices.cardinality.setup.remaining": "({0} of {1} set up)",
	"enroll.choices.setup.skip": "Skip set up",
	"enroll.securityQuestion.setup": "Setup secret question authentication",
	"security.disliked_food": "What is the food you least liked as a child?",
	"security.name_of_first_plush_toy": "What is the name of your first stuffed animal?",
	"security.first_award": "What did you earn your first medal or award for?",
	"security.favorite_security_question": "What is your favorite security question?",
	"security.favorite_toy": "What is the toy/stuffed animal you liked the most as a kid?",
	"security.first_computer_game": "What was the first computer game you played?",
	"security.favorite_movie_quote": "What is your favorite movie quote?",
	"security.first_sports_team_mascot": "What was the mascot of the first sports team you played on?",
	"security.first_music_purchase": "What music album or song did you first purchase?",
	"security.favorite_art_piece": "What is your favorite piece of art?",
	"security.grandmother_favorite_desert": "What was your grandmother's favorite dessert?",
	"security.first_thing_cooked": "What was the first thing you learned to cook?",
	"security.childhood_dream_job": "What was your dream job as a child?",
	"security.first_kiss_location": "Where did you have your first kiss?",
	"security.place_where_significant_other_was_met": "Where did you meet your spouse/significant other?",
	"security.favorite_vacation_location": "Where did you go for your favorite vacation?",
	"security.new_years_two_thousand": "Where were you on New Year's Eve in the year 2000?",
	"security.favorite_speaker_actor": "Who is your favorite speaker/orator?",
	"security.favorite_book_movie_character": "Who is your favorite book/movie character?",
	"security.favorite_sports_player": "Who is your favorite sports player?",
	"enroll.password.setup": "Select a password",
	"save.password": "Save password",
	"enroll.sms.setup": "Receive a code via SMS to authenticate",
	"enroll.sms.try_again": "The number you entered seems invalid. If the number is correct, please try again.",
	"enroll.call.setup": "Follow phone call instructions to authenticate",
	"enroll.onprem.username.placeholder": "Enter {0} username",
	"enroll.onprem.username.tooltip": "Enter {0} username",
	"enroll.onprem.passcode.placeholder": "Enter {0} passcode",
	"enroll.onprem.passcode.tooltip": "Enter {0} passcode",
	"enroll.symantecVip.subtitle": "Enter Credential ID and two consecutive generated codes",
	"enroll.symantecVip.credentialId.placeholder": "Enter credential ID",
	"enroll.symantecVip.credentialId.tooltip": "Enter credential ID",
	"enroll.symantecVip.passcode1.placeholder": "Security code 1",
	"enroll.symantecVip.passcode1.tooltip": "Security code 1",
	"enroll.symantecVip.passcode2.placeholder": "Security code 2",
	"enroll.symantecVip.passcode2.tooltip": "Security code 2",
	"enroll.yubikey.title": "Setup YubiKey",
	"enroll.yubikey.subtitle": "Insert your YubiKey into a USB port and tap it to generate a verification code",
	"enroll.totp.title": "Setup {0}",
	"enroll.totp.selectDevice": "Select your device type",
	"enroll.totp.downloadApp": "Download <a href=\"{0}\" class=\"inline-link\">{1} from the {2}</a> onto your mobile device.",
	"enroll.hotp.restricted": "Contact your administrator to continue enrollment.",
	"enroll.duo.title": "Setup Duo Security",
	"enroll.windowsHello.title": "Enroll Windows Hello",
	"enroll.windowsHello.subtitle": "Click below to enroll Windows Hello as a second form of authentication",
	"enroll.windowsHello.subtitle.loading": "Please wait while Windows Hello is loading...",
	"enroll.windowsHello.save": "Enroll Windows Hello",
	"enroll.windowsHello.error.notWindows": "Windows Hello can only be used on Windows Edge with Windows 10. Contact your admin for assistance.",
	"enroll.windowsHello.error.notConfiguredHtml": "Windows Hello is not configured. Select the <b>Start</b> button, then select <b>Settings</b> - <b>Accounts</b> - <b>Sign-in</b> to configure Windows Hello.",
	"verify.windowsHello.subtitle": "Verify your identity with Windows Hello",
	"verify.windowsHello.subtitle.loading": "Please wait while Windows Hello is loading...",
	"verify.windowsHello.subtitle.signingIn": "Signing in to Okta...",
	"verify.windowsHello.subtitle.signingIn.generic": "Signing in...",
	"verify.windowsHello.subtitle.signingIn.specific": "Signing in to {0}...",
	"verify.windowsHello.save": "Verify with Windows Hello",
	"verify.windowsHello.error.notFound": "Your Windows Hello enrollment does not match our records. Contact your administrator for assistance.",
	"verify.windowsHello.error.notFound.selectAnother": "Your Windows Hello enrollment does not match our records. Select another factor or contact your administrator for assistance.",
	"enroll.u2f.title": "Setup Security Key (U2F)",
	"enroll.u2f.save": "Register Security Key",
	"enroll.u2f.general2": "Make sure you have a Security Key. If already inserted, remove it now.<br>If you have a Bluetooth Security Key, turn on your computer's Bluetooth.",
	"enroll.u2f.general3": "Click the button below to register",
	"enroll.u2f.instructions": "Insert your Security Key into a USB port on this computer. If it has a button or gold disk, tap it.",
	"enroll.u2f.instructionsBluetooth": "If you are using a Bluetooth Security Key, press the button.",
	"u2f.error.factorNotSupported": "Security Key (U2F) is not supported on this browser. Select another factor or contact your admin for assistance.",
	"u2f.error.factorNotSupported.oneFactor": "Security Key (U2F) is not supported on this browser. Contact your admin for assistance.",
	"u2f.error.other": "An unknown error has occured. Try again or select another factor.",
	"u2f.error.other.oneFactor": "An unknown error has occured. Try again or contact your admin for assistance.",
	"u2f.error.badRequest": "There was an error with the U2F request. Try again or select another factor.",
	"u2f.error.badRequest.oneFactor": "There was an error with the U2F request. Try again or contact your admin for assistance.",
	"u2f.error.unsupported": "The security key is unsupported. Select another factor.",
	"u2f.error.unsupported.oneFactor": "The security key is unsupported. Contact your admin for assistance.",
	"u2f.error.timeout": "You have timed out of the authentication period. Please try again.",
	"verify.u2f.instructions": "Insert your Security Key. If it has a button or gold disk, tap it.",
	"verify.u2f.instructionsBluetooth": "If you are using a Bluetooth Security Key, turn on your computer's Bluetooth and press the button.",
	"verify.u2f.retry": "Retry",
	"enroll.customFactor.subtitle": "Clicking below will redirect to MFA enrollment with {0}",
	"enroll.customFactor.save": "Enroll",
	"verify.customFactor.subtitle": "Clicking below will redirect to verification with {0}",
	"verify.customFactor.subtitle.redirect": "Redirecting to {0}...",
	"enroll.webauthn.biometric.title": "Set up security key or biometric authenticator",
	"enroll.webauthn.save": "Enroll",
	"enroll.webauthn.biometric.instructions": "Your browser will prompt to register a security key or biometric authenticator (Windows Hello, Touch ID, etc.). Follow the instructions to complete enrollment.",
	"enroll.webauthn.biometric.instructions.edge": "Note: If you are enrolling a security key and Windows Hello or PIN is enabled, you will need to select '<b>Cancel</b>' in the prompt before continuing.",
	"verify.webauthn.biometric.instructions": "Your browser or device will prompt you to verify with a security key or biometric authenticator. Follow the instructions to complete authentication.",
	"webauthn.biometric.error.factorNotSupported": "Security key or biometric authenticator is not supported on this browser. Select another factor or contact your admin for assistance.",
	"webauthn.biometric.error.factorNotSupported.oneFactor": "Security key or biometric authenticator is not supported on this browser. Contact your admin for assistance.",
	"enroll.webauthn.instructions.noSupportForBiometric": "Note: Some browsers may not support biometric authenticators.",
	"authfactor.webauthn.error": "You are currently unable to use a Security key or biometric authenticator. Try again.",
	"enroll.totp.enterCode": "Enter code displayed from the application",
	"enroll.totp.setupApp": "Launch {0} on your mobile device and select “Add an account”. Scan the QR code to continue.",
	"enroll.totp.setupGoogleAuthApp": "Launch {0}, tap the \"+\" icon, then select \"Scan barcode\".",
	"enroll.totp.cannotScan": "Can't scan?",
	"enroll.totp.aria.cannotScan": "Setup without scanning a QR code.",
	"enroll.totp.refreshBarcode": "Refresh code",
	"enroll.totp.cannotScanBarcode": "Can't scan QR Code?",
	"enroll.totp.manualSetupInstructions": "To set up manually enter your Okta Account username and then input the following in the Secret Key Field",
	"enroll.totp.manualSetupInstructions.generic": "To set up manually enter your Account username and then input the following in the Secret Key Field",
	"enroll.totp.manualSetupInstructions.specific": "To set up manually enter your {0} Account username and then input the following in the Secret Key Field",
	"enroll.totp.sharedSecretInstructions": "Enter your Okta Account username and enter the following in the Secret Key Field",
	"enroll.totp.sharedSecretInstructions.generic": "Enter your Account username and enter the following in the Secret Key Field",
	"enroll.totp.sharedSecretInstructions.specific": "Enter your {0} Account username and enter the following in the Secret Key Field",
	"enroll.totp.sendSms": "Send activation link via SMS",
	"enroll.totp.sendEmail": "Send activation link via email",
	"enroll.totp.setupManually": "Setup manually without push notification",
	"enroll.totp.sharedSecretInstructions.aria.secretKey": "setup key {0}",
	"enroll.totp.enrollViaEmail.title": "Activation email sent!",
	"enroll.totp.enrollViaEmail.msg": "Open the email from your mobile device.",
	"enroll.totp.enrollViaSms.title": "SMS sent!",
	"enroll.totp.enrollViaSms.msg": "View the SMS on your mobile device.",
	"recoveryChallenge.sms.title": "Enter verification code sent via SMS",
	"recoveryChallenge.call.title": "Enter verification code received via Voice Call",
	"mfa.factors.dropdown.title": "Select an authentication factor",
	"mfa.factors.dropdown.sr.text": "Select Authentication Factor - {0} Factor Selected",
	"mfa.duoSecurity.push": "Push — {0}",
	"mfa.duoSecurity.sms": "SMS — {0}",
	"mfa.duoSecurity.call": "Call — {0}",
	"mfa.switch": "Switch Factor",
	"mfa.challenge.title": "Enter your {0} passcode",
	"mfa.challenge.orEnterCode": "Or enter code",
	"mfa.challenge.totp.subtitle.multiple": "Enter code from any registered {0} device.",
	"mfa.emailVerification.checkEmail": "To finish signing in, click the link in your email.",
	"mfa.emailVerification.title": "Sign in using a link sent to your email.",
	"mfa.emailVerification.subtitle": "Emails will be sent to {0}",
	"mfa.emailVerification.otc.finish": "To finish signing in, enter the code which was emailed to you.",
	"oktaverify.send": "Send Push",
	"oktaverify.resend": "Re-send Push",
	"oktaverify.sent": "Push sent!",
	"oktaverify.rejected": "You have chosen to reject this login.",
	"oktaverify.rejected.upgradeRequired.ios": "Verification failed because your Okta Verify version is no longer supported. To sign in, please update Okta Verify on the App Store, then try again.",
	"oktaverify.rejected.upgradeRequired.android": "Verification failed because your Okta Verify version is no longer supported. To sign in, please update Okta Verify on Google Play, then try again.",
	"oktaverify.timeout": "Your push notification has expired.",
	"oktaverify.warning": "Haven't received a push notification yet? Try opening the Okta Verify App on your phone.",
	"oktaverify.numberchallenge.instruction": "On your phone, tap <span class=\"challenge-number\">{0}</span> on Okta Verify prompt to continue.",
	"oktaverify.numberchallenge.explain": "This extra step helps us make sure it's really you signing in.",
	"primaryauth.title": "Sign In",
	"primaryauth.username.placeholder": "Username",
	"primaryauth.username.tooltip": "Username",
	"primaryauth.password.placeholder": "Password",
	"primaryauth.password.tooltip": "Password",
	"primaryauth.submit": "Sign In",
	"primaryauth.newUser.tooltip": "This is the first time you are connecting to {0} from this browser",
	"primaryauth.newUser.tooltip.close": "Close",
	"oktaVerify.description": "To access this resource, your organization requires you to sign in with Okta FastPass.",
	"oktaVerify.appDescription": "To access {0}, your organization requires you to sign in with Okta FastPass.",
	"oktaVerify.button": "Sign in with Okta FastPass",
	"signinWithWebAuthn.button": "Sign in with security key or biometrics",
	"idpDiscovery.email.placeholder": "Email",
	"password.forgot.email.or.username.placeholder": "Email or Username",
	"password.forgot.email.or.username.tooltip": "Email or Username",
	"password.forgot.sendText": "Reset via SMS",
	"password.forgot.sendEmail": "Reset via Email",
	"password.forgot.call": "Reset via Voice Call",
	"password.forgot.emailSent.title": "Email sent!",
	"password.forgot.emailSent.desc": "Email has been sent to {0} with instructions on resetting your password.",
	"password.forgot.question.title": "Answer Forgotten Password Challenge",
	"password.forgot.question.submit": "Reset Password",
	"password.forgot.code.notReceived": "Didn't receive a code? Reset via email",
	"password.forgot.noFactorsEnabled": "No password reset options available. Please contact your administrator.",
	"password.reset.title": "Reset your Okta password",
	"password.reset.title.generic": "Reset your password",
	"password.reset.title.specific": "Reset your {0} password",
	"password.complexity.requirements": "Password requirements: {0}.",
	"password.complexity.history": "Your password cannot be any of your last {0} passwords.",
	"password.complexity.minAgeMinutes": "At least {0} minute(s) must have elapsed since you last changed your password.",
	"password.complexity.minAgeHours": "At least {0} hour(s) must have elapsed since you last changed your password.",
	"password.complexity.minAgeDays": "At least {0} day(s) must have elapsed since you last changed your password.",
	"password.reset.verification": "Verify with one of the following factors to reset your password.",
	"password.reset.revokeSessions": "Sign me out of all other devices.",
	"password.complexity.length": "at least {0} characters",
	"password.complexity.list.element": ", {0}",
	"password.complexity.lowercase": "a lowercase letter",
	"password.complexity.uppercase": "an uppercase letter",
	"password.complexity.number": "a number",
	"password.complexity.symbol": "a symbol",
	"password.complexity.no_username": "no parts of your username",
	"password.complexity.no_first_name": "does not include your first name",
	"password.complexity.no_last_name": "does not include your last name",
	"password.complexity.requirements.header": "Password requirements:",
	"password.complexity.length.description": "At least {0} characters",
	"password.complexity.lowercase.description": "A lowercase letter",
	"password.complexity.uppercase.description": "An uppercase letter",
	"password.complexity.number.description": "A number",
	"password.complexity.symbol.description": "A symbol",
	"password.complexity.no_username.description": "No parts of your username",
	"password.complexity.no_first_name.description": "Does not include your first name",
	"password.complexity.no_last_name.description": "Does not include your last name",
	"password.complexity.history.description": "Your password cannot be any of your last {0} passwords",
	"password.complexity.minAgeMinutes.description": "At least {0} minute(s) must have elapsed since you last changed your password",
	"password.complexity.minAgeHours.description": "At least {0} hour(s) must have elapsed since you last changed your password",
	"password.complexity.minAgeDays.description": "At least {0} day(s) must have elapsed since you last changed your password",
	"password.expired.submit": "Change Password",
	"password.expired.title": "Your Okta password has expired",
	"password.expired.title.generic": "Your password has expired",
	"password.expired.title.specific": "Your {0} password has expired",
	"password.expiring.later": "Remind me later",
	"password.expiring.title": "Your password will expire in {0} days",
	"password.expiring.today": "Your password will expire later today",
	"password.expiring.soon": "Your password is expiring soon",
	"password.expiring.subtitle": "When password expires you may be locked out of Okta Mobile, mobile email, and other services.",
	"password.expiring.subtitle.generic": "When password expires you will be locked out of your account.",
	"password.expiring.subtitle.specific": "When password expires you will be locked out of your {0} account.",
	"password.expired.custom.submit": "Go to {0}",
	"password.expired.custom.subtitle": "This password is set on another website. Click the button below to go there and set a new password.",
	"password.expiring.soon.subtitle.generic": "When password expires you will be locked out of your account. This password is set on another website. Click the button below to go there and set a new password.",
	"password.expiring.soon.subtitle.specific": "When password expires you will be locked out of your {0} account. This password is set on another website. Click the button below to go there and set a new password.",
	"hcaptcha.footer.label": "This site is protected by hCaptcha and its <$1>Privacy Policy</$1> and <$2>Terms of Service</$2> apply.",
	"account.unlock.title": "Unlock account",
	"account.unlock.email.or.username.placeholder": "Email or username",
	"account.unlock.email.or.username.tooltip": "Email or username",
	"account.unlock.sendText": "Send SMS",
	"account.unlock.voiceCall": "Voice Call",
	"account.unlock.sendEmail": "Send Email",
	"account.unlock.emailSent.title": "Email sent!",
	"account.unlock.emailSent.desc": "Email has been sent to {0} with instructions on unlocking your account.",
	"account.unlock.question.title": "Answer Unlock Account Challenge",
	"account.unlock.question.submit": "Unlock Account",
	"account.unlock.unlocked.title": "Account successfully unlocked!",
	"account.unlock.unlocked.desc": "You can log in using your existing username and password.",
	"account.unlock.code.notReceived": "Didn't receive a code? Unlock via email",
	"account.unlock.noFactorsEnabled": "No unlock options available. Please contact your administrator.",
	"account.unlock.authenticatorRequired.single": "To unlock you account, select the following authenticator.",
	"account.unlock.authenticatorRequired.multiple": "To unlock your account, select one of the following authenticators.",
	"contact.support": "If you didn't provide a secondary email address or don't have access to email, please contact your administrator at {0}",
	"customauth.sign.in.with.label": "Sign in with {0}",
	"socialauth.divider.text": "OR",
	"socialauth.facebook.label": "Sign in with Facebook",
	"socialauth.google.label": "Sign in with Google",
	"socialauth.linkedin.label": "Sign in with LinkedIn",
	"socialauth.microsoft.label": "Sign in with Microsoft",
	"socialauth.apple.label": "Sign in with Apple",
	"socialauth.github.label": "Sign in with GitHub",
	"socialauth.gitlab.label": "Sign in with GitLab",
	"socialauth.yahoo.label": "Sign in with Yahoo",
	"socialauth.line.label": "Sign in with LINE",
	"socialauth.paypal.label": "Sign in with PayPal",
	"socialauth.paypal_sandbox.label": "Sign in with PayPal Sandbox",
	"socialauth.salesforce.label": "Sign in with Salesforce",
	"socialauth.amazon.label": "Sign in with Amazon",
	"socialauth.yahoojp.label": "Sign in with Yahoo Japan",
	"socialauth.discord.label": "Sign in with Discord",
	"socialauth.adobe.label": "Sign in with Adobe",
	"socialauth.orcid.label": "Sign in with ORCiD",
	"socialauth.spotify.label": "Sign in with Spotify",
	"socialauth.xero.label": "Sign in with Xero",
	"socialauth.quickbooks.label": "Sign in with Quickbooks",
	"socialauth.popup.title": "External Identity Provider User Authentication",
	"authbutton.divider.text": "or",
	"registration.signup.label": "Don't have an account?",
	"registration.signup.text": "Sign up",
	"registration.complete.title": "Verification email sent",
	"registration.complete.confirm.text": "To finish signing in, check your email.",
	"registration.form.title": "Create Account",
	"registration.form.submit": "Register",
	"registration.passwordComplexity.minLength": "At least {0} character(s)",
	"registration.passwordComplexity.minLower": "At least {0} lowercase letter(s)",
	"registration.passwordComplexity.minUpper": "At least {0} uppercase letter(s)",
	"registration.passwordComplexity.minNumber": "At least {0} number(s)",
	"registration.passwordComplexity.minSymbol": "At least {0} symbol(s)",
	"registration.passwordComplexity.excludeUsername": "Does not contain part of username",
	"registration.passwordComplexity.excludeAttribute": "Does not contain '{0}'",
	"registration.required.fields.label": "* indicates required field",
	"registration.default.callbackhook.error": "We could not process your registration at this time. Please try again later.",
	"registration.error.userName.invalidEmail": "Invalid email address",
	"registration.error.password.passwordRequirementsNotMet": "Password requirements were not met",
	"registration.error.password.passwordRequirementsNotMet.prefix": "Password requirements were not met:",
	"registration.error.userName.notUniqueWithinOrg": "An account with that {0} already exists",
	"registration.error.notUniqueWithinOrg.Email": "A user with this Email already exists",
	"registration.error.notUniqueWithinOrg.custom": "A user with this {0} already exists",
	"registration.error.invalidLoginEmail.Email": "'Email' must be in the form of an email address",
	"registration.error.invalidLoginEmail.custom": "'{0}' must be in the form of an email address",
	"registration.error.doesNotMatchPattern.Email": "Provided value for property 'Email' does not match required pattern",
	"registration.error.doesNotMatchPattern.custom": "Provided value for property '{0}' does not match required pattern",
	"registration.error.generic": "There was an error creating your account. Please try registering again.",
	"registration.error.request.not.completed": "Your request could not be completed. Try again later.",
	"registration.model.validation.field.string.too.short": "Please enter something at least {0} characters long",
	"registration.model.validation.field.string.too.long": "Please enter something shorter than {0} characters",
	"piv.card": "PIV Card",
	"piv.card.insert": "Please insert your PIV card and select the user certificate.",
	"piv.card.error": "Certificate authentication failed. Contact your admin.",
	"piv.card.error.empty": "No certificate selected. Choose a certificate and try again.",
	"piv.card.error.invalid": "Certificate validation failed. Choose another certificate and try again.",
	"piv.cac.card": "Sign in with PIV / CAC card",
	"piv.cac.title": "PIV / CAC card",
	"piv.cac.card.insert": "Please insert your PIV / CAC card and select the user certificate.",
	"piv.cac.error": "There was an error signing in. Click the button below to try again.",
	"unsupported.oneDrive.title": "Your OneDrive version is not supported",
	"unsupported.oneDrive.desc": "Upgrade now by installing the OneDrive for Business Next Generation Sync Client to login to Okta",
	"unsupported.oneDrive.action": "Learn how to upgrade",
	"unsupported.cookies.title": "Cookies are required",
	"unsupported.cookies.desc": "Cookies are disabled on your browser. Please enable Cookies and refresh this page.",
	"unsupported.cookies.action": "Refresh",
	"deviceTrust.sso.text": "Sign in to access company resources",
	"deviceTrust.sso.subtitle.2": "Your company uses Okta Mobile to get you approved to access this app.",
	"deviceTrust.sso.button": "Sign in with Okta Mobile",
	"deviceTrust.sso.expire.title": "Oops let's get you back on track",
	"deviceTrust.sso.redirectText": "Verifying your identity",
	"deviceTrust.sso.expire.subtitle": "Unfortunately the steps required to sign in took longer than expected and your attempt timed out.",
	"deviceTrust.sso.expire.button": "Sign in using Okta Mobile",
	"deviceTrust.universalLink.fallback.getOktaMobile.title": "Get Okta Mobile",
	"deviceTrust.universalLink.fallback.getOktaMobile.subtitle": "Go to the {0}AppStore{1}, {0}search{1} for {0}Okta Mobile{1} and tap on {0}GET{1} Okta Mobile. Once installed, sign in to Okta Mobile and follow the instructions to secure your device.",
	"core.auth.factor.signedNonce.error.invalidDevice": "Your device or account was invalidated. If this is unexpected, contact your administrator for help.",
	"core.auth.factor.signedNonce.error": "Your device or account was invalidated. Re-enroll your account on Okta Verify and try again.",
	"user.fail.verifyIdentity": "Couldn’t verify your identity",
	"loopback.polling.cancel.link": "Cancel and take me to sign in",
	"loopback.polling.cancel.link.with.form.error": "Take me to sign in",
	"customUri.subtitle": "Launching Okta Verify...",
	"customUri.content": "<div class=\"skinny-content\"> If nothing prompts from the browser, <a href=\"#\" id=\"launch-ov\" class=\"link\">click here</a> to launch Okta Verify, or make sure Okta Verify is installed.</div>",
	"customUri.title": "Click \"Open Okta Verify\" on the browser prompt",
	"customUri.required.content.prompt": "Didn’t get a prompt?",
	"customUri.required.content.download.title": "Don’t have Okta Verify?",
	"customUri.required.content.download.linkText": "Download here",
	"customUri.required.content.button": "Launch Okta Verify",
	"universalLink.title": "Sign in with Okta FastPass",
	"universalLink.content": "If Okta Verify did not open automatically, tap Open Okta Verify.",
	"appLink.title": "Sign in with Okta FastPass",
	"appLink.content": "If Okta Verify did not open automatically, tap Open Okta Verify.",
	"oktaVerify.open.button": "Open Okta Verify",
	"oktaVerify.reopen.button": "Reopen Okta Verify",
	"chrome_dtc.title": "Collecting device signals",
	"consent.required.text": "<b>{0}</b> would like to access:",
	"consent.required.description": "By clicking Allow Access, you allow the actions listed above.",
	"consent.required.termsOfService": "Terms of Service",
	"consent.required.privacyPolicy": "Privacy Policy",
	"consent.required.consentButton": "Allow Access",
	"consent.required.cancelButton": "Don't Allow",
	"granular.consent.scopes.title": "<$1>{0}</$1> <$2>wants to access your account</$2>",
	"granular.consent.scopes.description": "Allowing access will share",
	"admin.consent.group.user.group": "User and groups",
	"admin.consent.group.resource.policy": "Resource and policies",
	"admin.consent.group.hook": "Hooks",
	"admin.consent.group.system": "System",
	"polling.title": "There are too many users trying to sign in right now. We will automatically retry in {0} seconds.",
	"poll.form.title": "Unable to complete your request",
	"poll.form.message": "We will automatically retry in <$1>{0}</$1> seconds.",
	"cert.authentication.title": "Certificate authentication",
	"common.logo.alt": "aria logo",
	"oie.authenticator.enroll.error.fail": "Unable to enroll authenticator. Try again.",
	"oie.authenticator.verify.error.fail": "Unable to verify authenticator. Try again.",
	"oie.configuration.error": "Something went wrong. Potential misconfiguration detected. Please contact support.",
	"oie.auth.logo.aria.label": "Authenticator logo",
	"oie.challenge.answer.showAnswer": "Show answer",
	"oie.challenge.answer.hideAnswer": "Hide answer",
	"oie.form.field.optional": "Optional",
	"oie.form.field.optional.description": "Fields are required unless marked optional.",
	"oie.password.label": "Password",
	"oie.password.newPasswordLabel": "New password",
	"oie.password.authenticator.description": "Choose a password for your account",
	"oie.password.challenge.title": "Verify with your password",
	"oie.password.enroll.title": "Set up password",
	"oie.password.passwordLabel": "Enter password",
	"oie.password.confirmPasswordLabel": "Re-enter password",
	"oie.password.showPassword": "Show password",
	"oie.password.showConfirmPassword": "Show re-entered password",
	"oie.password.hidePassword": "Hide password",
	"oie.password.hideConfirmPassword": "Hide re-entered password",
	"oie.password.reset.verification": "Verify with one of the following security methods to reset your password.",
	"idx.password.expiring.message": "When your password expires, you will have to change your password before you can login to your {0} account.",
	"oie.password.incorrect.message": "Password is incorrect",
	"oie.selfservice.reset.password.not.allowed": "Reset password is not allowed at this time. Please contact support for assistance.",
	"password.common": "This password was found in a list of commonly used passwords. Please try another password.",
	"idx.recovery.completed": "You can now sign in with your existing username and new password.",
	"oie.phone.label": "Phone",
	"oie.phone.authenticator.description": "Verify with a code sent to your phone",
	"oie.phone.enroll.sms.label": "SMS",
	"oie.phone.enroll.voice.label": "Voice call",
	"oie.phone.enroll.title": "Set up phone authentication",
	"oie.phone.enroll.call.subtitle": "Enter your phone number to receive a verification code via voice call.",
	"oie.phone.enroll.sms.subtitle": "Enter your phone number to receive a verification code via SMS.",
	"oie.phone.sms.primaryButton": "Receive a code via SMS",
	"oie.phone.sms.secondaryButton": "Receive an SMS instead",
	"oie.phone.call.primaryButton": "Receive a code via voice call",
	"oie.phone.call.secondaryButton": "Receive a voice call instead",
	"oie.phone.verify.sms.resendText": "Haven't received an SMS?",
	"oie.phone.verify.sms.codeSentText": "A code was sent to",
	"oie.phone.verify.sms.sendText": "Send a code via SMS to",
	"oie.phone.verify.call.sendText": "Send a code via voice call to",
	"oie.phone.verify.call.resendText": "Haven't received a call?",
	"oie.phone.verify.call.resendLinkText": "Call again",
	"oie.phone.verify.sms.resendLinkText": "Send again",
	"oie.phone.verify.enterCodeText": "Enter the code below to verify.",
	"oie.phone.verify.title": "Verify with your phone",
	"oie.phone.alternate.title": "your phone",
	"oie.phone.carrier.charges": "Carrier messaging charges may apply",
	"oie.phone.invalid": "Invalid Phone Number.",
	"oie.email.label": "Email",
	"oie.email.authenticator.description": "Verify with a link or code sent to your email",
	"oie.email.mfa.title": "Verify with your email",
	"oie.email.challenge.mfa.title": "Get a verification email",
	"oie.email.verify.primaryButton": "Send me an email",
	"oie.email.verify.subtitle.text.with.email": "Send a verification email to <$1>{0}</$1> by clicking on \"Send me an email\".",
	"oie.email.verify.subtitle.text.without.email": "Send a verification email by clicking on \"Send me an email\".",
	"oie.email.verify.alternate.magicLinkToEmailAddress": "We sent an email to <$1>{0}</$1>. ",
	"oie.email.verify.alternate.magicLinkToYourEmail": "We sent you a verification email. ",
	"oie.email.verify.alternate.instructions": "Click the verification link in your email to continue or enter the code below.",
	"oie.email.verify.alternate.show.verificationCode.text": "Enter a verification code instead",
	"oie.email.verify.alternate.verificationCode.instructions": "Enter the verification code in the text box.",
	"oie.email.enroll.subtitle": "Please check your email and enter the code below.",
	"oie.email.return.link.expired.title": "Verify with your email",
	"oie.profile.additional.title": "Additional Profile information",
	"oie.profile.additional.secondemail.subtitle": "Use a second email to <$1> recover your account </$1> in case you become locked out. This email must be verified after setup.",
	"oie.webauthn.label": "Security Key or Biometric Authenticator",
	"oie.webauthn.description": "Use a security key or a biometric authenticator to sign in",
	"oie.enroll.webauthn.title": "Set up security key or biometric authenticator",
	"oie.enroll.webauthn.instructions": "You will be prompted to register a security key or biometric authenticator (Windows Hello, Touch ID, Face ID, etc.). Follow the instructions to complete set up.",
	"oie.enroll.webauthn.uv.required.instructions": "Biometric verification or a PIN is required to setup this authenticator.",
	"oie.enroll.webauthn.instructions.edge": "Note: If you are enrolling a security key and Windows Hello or PIN is enabled, you will need to select 'Cancel' in the prompt before continuing.",
	"oie.enroll.webauthn.save": "Set up",
	"oie.webauthn.error.not.supported": "Security key or biometric authenticator is not supported on this browser. Contact your admin for assistance.",
	"oie.verify.webauth.title": "Verify with Security Key or Biometric Authenticator",
	"oie.verify.webauthn.instructions": "You will be prompted to use a security key or biometric verification (Windows Hello, Touch ID, etc.). Follow the instructions to complete verification.",
	"oie.verify.webauthn.uv.required.instructions": "Biometric verification or a PIN is required to sign in with this authenticator.",
	"oie.verify.webauthn.cant.verify": "Can't verify?",
	"oie.verify.webauthn.cant.verify.biometric.authenticator.title": "Are you trying to use a biometric authenticator?",
	"oie.verify.webauthn.cant.verify.biometric.authenticator.description1": "Biometric authenticators (fingerprint, face recognition, PIN) will only work on the same device on which they were set up.",
	"oie.verify.webauthn.cant.verify.biometric.authenticator.description2": "If available, set up another security method on the device you used to set up your biometric authenticator.",
	"oie.verify.webauthn.cant.verify.security.key.title": "Are you trying to use a security key?",
	"oie.verify.webauthn.cant.verify.security.key.description": "If you have set up a security key, insert it in a USB port when prompted by the browser and tap on the button or gold disk. Security keys can work on multiple devices.",
	"oie.verify.webauthn.cant.verify.enrollment.step1": "Open your Okta Dashboard (e.g. yourcompany.okta.com) on the device you used to setup your security key or biometric authenticator",
	"oie.verify.webauthn.cant.verify.enrollment.step2": "Go to Settings > Security Methods",
	"oie.verify.webauthn.cant.verify.enrollment.step3": "On Okta Verify, click \"Set up\"",
	"oie.verify.webauthn.cant.verify.enrollment.step4": "Scan the QR code using Okta Verify and follow instructions to finish enrolling your account",
	"oie.enroll.webauthn.rk.link": "If you haven't set up your security key or biometric authentication, you can complete the process after you sign in using <a href=\"#\" class=\"setup-webauthn-residentkey-link\">this link</a>.",
	"oie.security.question.label": "Security Question",
	"oie.security.question.enroll.title": "Set up security question",
	"oie.security.question.challenge.title": "Verify with your Security Question",
	"oie.security.question.questionKey.label": "Choose a security question",
	"oie.security.question.createQuestion.label": "Create my own security question",
	"oie.security.question.authenticator.description": "Choose a security question and answer that will be used for signing in",
	"securityQuestion.answer.tooShort.arg": "The security question answer must be at least {0} characters in length",
	"oie.okta_verify.label": "Okta Verify",
	"oie.enroll.okta_verify.setup.subtitle": "To continue, you'll need an Okta Verify account on this device.",
	"oie.enroll.okta_verify.setup.download": "If you don't have Okta Verify installed, <$1>download the app</$1>.",
	"oie.enroll.okta_verify.setup.openOv": "Open Okta Verify and follow the steps to add your account.",
	"oie.enroll.okta_verify.setup.signInUrl": "When prompted, choose Sign In, then enter the sign-in URL:",
	"oie.enroll.okta_verify.setup.instructions": "Follow the instructions to set up Okta Verify on this device, then return here and refresh this page.",
	"oie.okta_verify.authenticator.description": "Okta Verify is an authenticator app, installed on your phone, used to prove your identity",
	"oie.enroll.okta_verify.setup.title": "Set up Okta Verify",
	"oie.enroll.okta_verify.setup.email.title": "Check your email",
	"oie.enroll.okta_verify.setup.sms.title": "Check your text messages",
	"oie.enroll.okta_verify.setup.skipAuth.subtitle": "To set up Okta Verify on additional devices, you can copy an existing Okta Verify account onto a new device.",
	"oie.enroll.okta_verify.setup.skipAuth.openOv": "Open Okta Verify on any of your other Okta Verify devices (<$1>{0}</$1>).",
	"oie.enroll.okta_verify.setup.skipAuth.selectAccount": "In the app, select your account.",
	"oie.enroll.okta_verify.setup.skipAuth.addAccount": "Choose <$1>Add Account to Another Device.</$1>",
	"oie.enroll.okta_verify.setup.skipAuth.followInstruction": "Follow the rest of the instructions shown in Okta Verify.",
	"oie.enroll.okta_verify.setup.skipAuth.canBeClosed": "This screen can be closed at any time.",
	"oie.enroll.okta_verify.select.channel.description": "Which option do you want to try?",
	"oie.enroll.okta_verify.select.channel.qrcode.label": "Scan a QR code",
	"oie.enroll.okta_verify.select.channel.email.label": "Email me a setup link",
	"oie.enroll.okta_verify.select.channel.sms.label": "Text me a setup link",
	"oie.enroll.okta_verify.select.channel.title": "More options",
	"oie.enroll.okta_verify.channel.email.label": "Email",
	"oie.enroll.okta_verify.enroll.channel.email.title": "Set up Okta Verify via email link",
	"oie.enroll.okta_verify.enroll.channel.sms.title": "Set up Okta Verify via SMS",
	"oie.enroll.okta_verify.channel.email.description": "Make sure you can access the email on your mobile device.",
	"oie.enroll.okta_verify.channel.sms.description": "Make sure you can access the text on your mobile device.",
	"oie.enroll.okta_verify.qrcode.step1": "On your mobile device, download the Okta Verify app from the App Store (iPhone and iPad) or Google Play (Android devices).",
	"oie.enroll.okta_verify.qrcode.step2": "Open the app and follow the instructions to add your account",
	"oie.enroll.okta_verify.qrcode.step3": "When prompted, tap Scan a QR code, then scan the QR code below:",
	"oie.enroll.okta_verify.qrcode.cannotScan": "Can't scan?",
	"oie.enroll.okta_verify.switch.channel.link.text": "Or <a href=\"#\" class=\"switch-channel-link\">try a different way</a> to set up Okta Verify.",
	"oie.enroll.okta_verify.email.info": "We sent an email to <span class=\"strong\">{0}</span> with an Okta Verify setup link. To continue, open the link on your mobile device.",
	"oie.enroll.okta_verify.email.notReceived": "Haven’t received an email? Check your spam folder or <a href=\"#\" class=\"resend-link\">send again</a>",
	"oie.enroll.okta_verify.sms.info": "We sent an SMS to <span class=\"strong\">{0}</span> with an Okta Verify setup link. To continue, open the link on your mobile device.",
	"oie.enroll.okta_verify.sms.notReceived": "Haven’t received an SMS? <a href=\"#\" class=\"resend-link\">Send again</a>",
	"oie.enroll.okta_verify.setupLink": "Send me the setup link",
	"oie.okta_verify.totp.title": "Enter a code",
	"oie.okta_verify.totp.enterCodeText": "Enter code from Okta Verify app",
	"oie.okta_verify.push.title": "Get a push notification",
	"oie.okta_verify.push.sent": "Push notification sent",
	"oie.okta_verify.push.resend": "Resend push notification",
	"oie.okta_verify.sendPushButton": "Send push",
	"oie.okta_verify.signed_nonce.label": "Use Okta FastPass",
	"oie.okta_verify.enroll.force.upgrade.title": "Update Okta Verify",
	"oie.authenticator.app.non_fips_compliant_enrollment_device_incompatible": "The device used to set up Okta Verify does not meet your organization’s security requirements because it is not FIPS compliant. Contact your administrator for help.",
	"oie.authenticator.app.non_fips_compliant_enrollment_app_update_required": "The Okta Verify version on the device used does not meet your organization’s security requirements. To add your account, update Okta Verify to the latest version, then try again.",
	"oie.authenticator.app.method.push.verify.enable.biometrics": "Your response was received, but your organization requires biometrics. Make sure your device supports biometrics, Okta Verify is up-to-date and biometrics are enabled for your account in Okta Verify, then try again.",
	"oie.authenticator.app.method.push.verify.enable.biometrics.title": "Enable biometrics in Okta Verify",
	"oie.authenticator.app.method.push.verify.enable.biometrics.description": "Your response was received, but your organization requires biometrics. Make sure you meet the following requirements, then try again:",
	"oie.authenticator.app.method.push.verify.enable.biometrics.point1": "Your device supports biometrics",
	"oie.authenticator.app.method.push.verify.enable.biometrics.point2": "Okta Verify is up-to-date",
	"oie.authenticator.app.method.push.verify.enable.biometrics.point3": "In Okta Verify, biometrics are enabled for your account",
	"oie.authenticator.app.method.push.enroll.enable.biometrics": "Your organization requires biometrics. To proceed, ensure your device supports biometrics, then add your account and enable biometrics when prompted.",
	"oie.authenticator.app.method.push.enroll.enable.biometrics.title": "Enable biometrics to add an account in Okta Verify",
	"oie.authenticator.oktaverify.method.totp.verify.enable.biometrics": "Your response was received, but your organization requires biometrics. Make sure your device supports biometrics, Okta Verify is up-to-date and biometrics are enabled for your account in Okta Verify, then try again.",
	"oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.title": "Enable biometrics in Okta Verify",
	"oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.description": "Your response was received, but your organization requires biometrics. Make sure you meet the following requirements, then try again:",
	"oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.point1": "Your device supports biometrics",
	"oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.point2": "Okta Verify is up-to-date",
	"oie.authenticator.oktaverify.method.totp.verify.enable.biometrics.point3": "In Okta Verify, biometrics are enabled for your account",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.mobile": "Your response was received, but your organization requires biometrics. Make sure your device supports biometrics, Okta Verify is up-to-date and biometrics are enabled for your account in Okta Verify, then try again.",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.desktop": "Your response was received, but your organization requires biometrics. Make sure your device supports biometrics, Okta Verify is up-to-date, biometrics are enabled for your account in Okta Verify and your device's biometric sensors are accessible, then try again.",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.title": "Biometrics needed for Okta Verify",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.description": "Your response was received, but your organization requires biometrics. Make sure you meet the following requirements, then try again:",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.point1": "Your device supports biometrics",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.point2": "Okta Verify is up-to-date",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.point3": "In Okta Verify, biometrics are enabled for your account",
	"oie.authenticator.oktaverify.method.fastpass.verify.enable.biometrics.point4": "Your device's biometric sensors are accessible",
	"oie.google_authenticator.label": "Google Authenticator",
	"oie.google_authenticator.authenticator.description": "Enter a temporary code generated from the Google Authenticator app.",
	"oie.enroll.google_authenticator.setup.title": "Set up Google Authenticator",
	"oie.enroll.google_authenticator.scanBarcode.title": "Scan barcode",
	"oie.enroll.google_authenticator.scanBarcode.description": "Launch Google Authenticator, tap the \"+\" icon, then select \"Scan barcode\".",
	"oie.enroll.google_authenticator.scanBarcode.cannotScan": "Can't scan?",
	"oie.enroll.google_authenticator.cannotScanBarcode.title": "Can't scan barcode?",
	"oie.enroll.google_authenticator.manualSetupInstructions": "To set up manually enter your Okta Account username and then input the following in the Secret Key Field",
	"oie.enroll.google_authenticator.enterCode.title": "Enter code displayed from application",
	"oie.verify.google_authenticator.otp.title": "Verify with Google Authenticator",
	"oie.verify.google_authenticator.otp.description": "Enter the temporary code generated in your Google Authenticator app",
	"oie.google_authenticator.otp.enterCodeText": "Enter code",
	"oie.verify.custom_otp.title": "Verify with {0}",
	"oie.verify.custom_otp.description": "Enter the code generated on your authenticator and verify.",
	"oie.custom_otp.description": "Enter a temporary code generated from an authenticator device.",
	"oie.custom_otp.authenticator.default.vendorName": "Custom OTP Authenticator",
	"oie.custom_otp.verify.passcode.label": "Enter code",
	"oie.verify.custom_app.title": "Verify with {0}",
	"oie.custom_app.push.sent": "Push notification sent",
	"oie.custom_app.push.resend": "Resend push notification",
	"oie.custom_app.push.warning": "Haven't received a push notification yet? Try opening {0} on your phone.",
	"oie.authenticator.custom_app.method.push.verify.enable.biometrics": "Your response was received, but your organization requires biometrics—like a fingerprint or facial scan—for access. Make sure your device meets the following requirements, then try again:",
	"oie.authenticator.custom_app.method.push.verify.enable.biometrics.title": "Enable biometrics in {0}",
	"oie.authenticator.custom_app.method.push.verify.enable.biometrics.description": "Your response was received, but your organization requires biometrics—like a fingerprint or facial scan—for access. Make sure your device meets the following requirements, then try again:",
	"oie.authenticator.custom_app.method.push.verify.enable.biometrics.point1": "Your device supports biometrics",
	"oie.authenticator.custom_app.method.push.verify.enable.biometrics.point2": "{0} is up-to-date",
	"oie.authenticator.custom_app.method.push.verify.enable.biometrics.point3": "In {0}, biometrics are enabled for your account",
	"oie.custom_app.sendPushButton": "Send push",
	"oie.custom_app.push.title": "Get a push notification",
	"oie.on_prem.authenticator.description": "Verify by entering a code generated by {0}.",
	"oie.on_prem.authenticator.default.vendorName": "Custom On-prem Authenticator",
	"oie.on_prem.enroll.title": "Set up {0}",
	"oie.on_prem.verify.title": "Verify with {0}",
	"oie.numberchallenge.warning": "Haven't received a push notification yet? Try opening the Okta Verify app on your device, or <$1>resend the push notification</$1>.",
	"oie.numberchallenge.instruction": "On your mobile device, open the Okta Verify prompt, then tap <$1>{0}</$1> in Okta Verify to continue.",
	"oie.numberchallenge.force.upgrade.title": "Update Okta Verify",
	"oie.authenticator.app.method.push.force.upgrade.number_challenge": "Your response was received, but your Okta Verify version is no longer supported by your organization. To verify your identity with push notifications, update Okta Verify to the latest version, then try again.",
	"oie.rsa.authenticator.description": "Verify by entering a code generated by RSA SecurID",
	"oie.duo.authenticator.description": "Verify your identity using Duo Security.",
	"oie.duo.enroll.title": "Set up Duo Security",
	"oie.duo.verify.title": "Verify with Duo Security",
	"oie.duo.iFrameError": "Error loading Duo. Try again or contact your admin for assistance.",
	"oie.authenticator.duo.error": "We were unable to verify with Duo. Try again.",
	"oie.idp.authenticator.description": "Redirect to verify with {0}.",
	"oie.idp.enroll.title": "Set up {0}",
	"oie.idp.enroll.description": "You will be redirected to enroll in {0}",
	"oie.idp.challenge.title": "Verify with {0}",
	"oie.idp.challenge.description": "You will be redirected to verify with {0}",
	"idx.identity.provider.auth.error": "There was a problem signing you into your identity provider. Please contact your administrator for help.",
	"oie.symantecVip.authenticator.description": "Verify by entering a temporary code from the {0} app.",
	"oie.symantecVip.enroll.title": "Set up {0}",
	"oie.symantecVip.enroll.description": "From the {0} app, enter your credential ID and two consecutive generated codes",
	"oie.symantecVip.challenge.title": "Verify with {0}",
	"oie.symantecVip.challenge.description": "Enter the generated security code from the {0} app.",
	"oie.symantecVip.verify.passcode.label": "Enter security code",
	"oie.yubikey.label": "YubiKey Authenticator",
	"oie.yubikey.authenticator.description": "Verify your identity using YubiKey",
	"oie.yubikey.enroll.title": "Set up YubiKey",
	"oie.yubikey.challenge.title": "Verify with YubiKey",
	"oie.yubikey.description": "Use your YubiKey to insert a verification code.",
	"oie.yubikey.passcode.label": "Insert then tap your YubiKey",
	"oie.custom.app.authenticator.title": "Get a push notification",
	"oie.custom.app.authenticator.description": "{0} is an authenticator app, installed on your phone, used to prove your identity",
	"oie.smartcard.authenticator.description": "Use a physical smart card, such as PIV or CAC, to sign in",
	"oie.select.authenticators.enroll.title": "Set up security methods",
	"oie.select.authenticators.enroll.subtitle": "Security methods help protect your account by ensuring only you have access.",
	"oie.select.authenticators.enroll.subtitle.custom": "Security methods help protect your {0} account by ensuring only you have access.",
	"oie.setup.required": "Set up required",
	"oie.setup.optional": "Set up optional",
	"oie.optional.authenticator.button.title": "Set up later",
	"oie.verify.authenticator.button.text": "Select",
	"oie.enroll.authenticator.button.text": "Set up",
	"oie.enroll.authenticator.usage.text.access.recovery": "Used for access or recovery",
	"oie.enroll.authenticator.usage.text.recovery": "Used for recovery",
	"oie.enroll.authenticator.usage.text.access": "Used for access",
	"oie.select.authenticators.verify.title": "Verify it's you with a security method",
	"oie.select.authenticators.verify.subtitle": "Select from the following options",
	"oie.success.text.signingIn": "Signing in",
	"oie.success.text.signingIn.with.ellipsis": "Signing in...",
	"oie.success.text.signingIn.with.appName": "Signing in to {0}",
	"oie.success.text.signingIn.with.appName.and.identifier": "Signing in to {0} as {1}",
	"oie.go.back": "Go back",
	"oie.enroll.switch.authenticator": "Return to authenticator list",
	"oie.enroll.skip.setup": "Skip set up",
	"oie.enroll.skip.profile": "Skip Profile",
	"oie.verification.switch.authenticator": "Verify with something else",
	"oie.remember": "Keep me signed in",
	"oie.try.again": "Try again",
	"enroll.title.oda": "Download Okta Verify",
	"enroll.title.oda.with.account": "Additional setup required to use Okta FastPass",
	"enroll.title.oda.without.account": "Set up an Okta Verify account",
	"enroll.title.mdm": "Additional setup required",
	"enroll.subtitle.fastpass": "On this device, do you already have an Okta Verify account for {0}?",
	"enroll.option.noaccount.fastpass": "No, I don’t have an account",
	"enroll.option.account.fastpass": "Yes, I already have an account",
	"enroll.explanation.p1": "To sign in using Okta Verify, you will need to set up Okta Verify on this device.",
	"enroll.explanation.p2": "In the app, follow the instructions to add an organizational account. When prompted, choose <span class=\"semi-strong\">Sign In</span>, then enter the <span class=\"semi-strong\">sign-in URL</span>:",
	"enroll.oda.android.step1": "On Google Play, download the Okta Verify app.",
	"enroll.oda.step1": "Open Okta Verify and follow the steps to add your account.",
	"enroll.oda.step2": "When prompted, choose Sign In, then enter the sign-in URL:",
	"enroll.oda.step3": "Download the Okta Verify app.",
	"enroll.oda.step6": "Finish setting up your account in Okta Verify, then try accessing this app again.",
	"enroll.oda.with.account.explanation": "Okta FastPass is a security method that can sign you in without needing your username.",
	"enroll.oda.with.account.subtitile1": "Already have Okta FastPass enabled for your account?",
	"enroll.oda.with.account.subtitile2": "Need to set up Okta FastPass for your account?",
	"enroll.oda.with.account.step1": "Make sure your Okta Verify is up-to-date, then try signing in again.",
	"enroll.oda.with.account.step2": "If your device has a work profile, make sure you are signing in from the same profile that Okta Verify is installed in.",
	"enroll.oda.with.account.step3": "On certain browsers and applications, Okta FastPass is not supported.",
	"enroll.oda.with.account.step4": "On this device, open the Okta Verify app.",
	"enroll.oda.with.account.step5": "On the list of accounts, tap your account for {0}.",
	"enroll.oda.with.account.step6": "Under the “Okta FastPass” section, tap Setup, then follow the instructions.",
	"enroll.oda.with.account.step7": "After your setup is complete, return here to try signing in again.",
	"enroll.oda.without.account.explanation": "To sign in with Okta FastPass, you’ll need to set up Okta Verify on this device.",
	"enroll.oda.without.account.step1": "If you don’t have Okta Verify installed, <a href=\"{0}\" target=\"_blank\" class=\"download-ov-link\" id=\"download-ov\">download the app.</a>",
	"enroll.oda.without.account.step4": "Finish setting up your account in Okta Verify, then try signing in again.",
	"enroll.appleStore": "the App Store",
	"enroll.googleStore": "Google Play",
	"enroll.copy.ios": "Apple®, App Store, and the Apple logo are trademarks of Apple Inc.",
	"enroll.copy.android": "Google Play and the Google Play logo are trademarks of Google LLC.",
	"enroll.explanation.mdm": "To access this app, your device needs to meet your organization's security requirements. Follow the instructions below to continue.",
	"enroll.oda.org.copyLink": "Copy sign-in URL to clipboard",
	"enroll.oda.org.copyLink.success": "Sign-in URL copied.",
	"enroll.mdm.copyLink": "Copy link to clipboard",
	"enroll.mdm.copyLink.success": "Link copied.",
	"enroll.mdm.step.copyLink": "Tap the Copy Link button below.",
	"enroll.mdm.step.pasteLink": "On this device, open your browser, then paste the copied link into the address bar.",
	"enroll.mdm.step.followInstructions": "Follow the instructions in your browser to set up <$1>{0}</$1>.",
	"enroll.mdm.step.relogin": "Logout and re-login and then try accessing the app again.",
	"oie.safe.mode.title": "Authenticator set up unavailable",
	"idx.error.server.safe.mode.enrollment.unavailable": "Set up is temporarily unavailable due to server maintenance. Try again later.",
	"oie.primaryauth.submit": "Sign in",
	"oie.registration.form.title": "Sign up",
	"oie.registration.form.submit": "Sign Up",
	"oie.registration.form.update.submit": "Submit",
	"oie.registration.form.customize.label": "{0}",
	"oie.registration.form.customize.buttonLabel": "{0}",
	"oie.user.profile.lastname": "Last name",
	"oie.user.profile.firstname": "First name",
	"oie.user.profile.primary.email": "Email",
	"oie.user.profile.secondary.email": "Secondary email",
	"oie.user.profile.middleName": "Middle name",
	"oie.user.profile.honorificPrefix": "Honorific prefix",
	"oie.user.profile.honorificSuffix": "Honorific suffix",
	"oie.user.profile.primaryEmail": "Primary email",
	"oie.user.profile.title": "Title",
	"oie.user.profile.displayName": "Display name",
	"oie.user.profile.nickName": "Nickname",
	"oie.user.profile.profileUrl": "Profile URL",
	"oie.user.profile.mobilePhone": "Mobile phone",
	"oie.user.profile.primaryPhone": "Primary phone",
	"oie.user.profile.streetAddress": "Street address",
	"oie.user.profile.city": "City",
	"oie.user.profile.state": "State",
	"oie.user.profile.zipCode": "ZIP Code",
	"oie.user.profile.postalCode": "Postal code",
	"oie.user.profile.countryCode": "Country code",
	"oie.user.profile.postalAddress": "Postal address",
	"oie.user.profile.preferredLanguage": "Preferred language",
	"oie.user.profile.locale": "Locale",
	"oie.user.profile.timezone": "Time zone",
	"oie.user.profile.userType": "User type",
	"oie.user.profile.employeeNumber": "Employee number",
	"oie.user.profile.costCenter": "Cost center",
	"oie.user.profile.organization": "Organization",
	"oie.user.profile.division": "Division",
	"oie.user.profile.department": "Department",
	"oie.user.profile.managerId": "Manager ID",
	"oie.user.profile.manager": "Manager",
	"oie.selfservice.unlock_user.landing.to.app.success.message": "Account successfully unlocked! Verify your account with a security method to continue.",
	"oie.selfservice.unlock_user.landing.to.app.signing.in.message": "Account successfully unlocked!",
	"oie.selfservice.unlock_user.success.message": "You can log in using your existing username and password.",
	"oie.selfservice.unlock_user.failed.message": "We are unable to unlock your account at this time, please contact your administrator",
	"oie.selfservice.user.unlock.not.allowed": "Self Service Unlock is not allowed at this time. Please contact support for assistance.",
	"idx.session.expired": "You have been logged out due to inactivity. Refresh or return to the sign in screen.",
	"oie.registration.is.not.enabled": "Sign up is not enabled for this organization.",
	"oie.forgot.password.is.not.enabled": "Forgot password is not enabled for this organization.",
	"idx.return.error": "Could not process this email link. Return to the screen where you requested it.",
	"idx.return.stale": "This email link is out of date. Return to the screen where you requested it.",
	"idx.invalid.forgot.password.token": "Your reset password token is no longer valid.",
	"idx.invalid.reset.password.token": "Your reset password token is no longer valid. Contact your administrator to obtain a new token.",
	"idx.reset.password.activating.user": "Reset password cannot be performed while the user is activating. Contact your administrator to obtain a new token.",
	tagsNotAllowed: tagsNotAllowed,
	"api.authn.error.PASSCODE_INVALID": "Invalid code. Try again.",
	"oie.feature.disabled": "The requested feature is not enabled in this environment.",
	"authfactor.challenge.suspended_factor": "This factor is suspended for your account due to too many failed attempts",
	"oie.post.password.update.auth.failure.error": "Your password has been updated but there was a problem signing you in. Please try again or contact your administrator.",
	"oie.assurance.unsatisfiable": "Unable to login to \"{0}\" at this time. For assistance, please contact your administrator.",
	"errors.E0000150": "You have reached the limit of sms requests, please try again later.",
	"errors.E0000151": "You have reached the limit of call requests, please try again later.",
	"oie.invalid.recovery.token": "The recovery token is invalid.",
	"oie.consent.scopes.admin.title": "would like to access:",
	"oie.consent.scopes.enduser.title": "would like to:",
	"oie.consent.scopes.granular.title": "<$1>{0}</$1> <$2>wants to access</$2>",
	"oie.consent.scopes.granular.description": "Allowing access will share",
	"oie.consent.enduser.accept.label": "Yes, it's me",
	"oie.consent.enduser.deny.label": "No, it's not me",
	"oie.consent.enduser.title": "Did you just try to sign in?",
	"oie.consent.enduser.email.allow.title": "Success! Return to the original tab or window",
	"oie.consent.enduser.email.allow.description": "To continue, please return to the original browser tab or window you used to verify.",
	"oie.consent.enduser.deny.description": "Close this window anytime.",
	"oie.return.to.original.tab": "Close this window anytime.",
	"idx.operation.cancelled.by.user": "Operation cancelled by user.",
	"idx.transferred.to.new.tab": "Flow continued in a new tab.",
	"idx.return.link.expired": "This email link has expired. To resend it, return to the screen where you requested it.",
	"idx.return.link.otponly.your.verification.code": "Your verification code",
	"idx.return.link.otponly.enter.code.on.page": "Enter this code on the {0} page.",
	"idx.return.link.otponly.enter.code.on.page.sign.in": "sign-in",
	"idx.return.link.otponly.enter.code.on.page.password.reset": "password reset",
	"idx.return.link.otponly.enter.code.on.page.account.unlock": "account unlock",
	"idx.return.link.otponly.enter.code.on.page.registration": "sign up",
	"idx.return.link.otponly.enter.code.on.sign.in.page": "Enter this code on the sign-in page.",
	"idx.return.link.otponly.enter.code.on.password.reset.page": "Enter this code on the password reset page.",
	"idx.return.link.otponly.enter.code.on.account.unlock.page": "Enter this code on the account unlock page.",
	"idx.return.link.otponly.enter.code.on.sign.up.page": "Enter this code on the sign up page.",
	"idx.return.link.otponly.request": "Request from:",
	"idx.return.link.otponly.warning.text": "If you didn’t request this code, you can ignore this message. Your account is safe and can only be accessed with this code.",
	"idx.enter.otp.in.original.tab": "Enter the OTP in your original authentication location.",
	"idx.return.link.otponly.app": "{0}",
	"idx.return.link.otponly.browser.on.os": "{0} on {1}",
	"geolocation.formatting.all": "{0}, {1}, {2}",
	"geolocation.formatting.partial": "{0}, {1}",
	"consent.scopes.email.label": "View your email address.",
	"consent.scopes.profile.label": "View your profile information.",
	"consent.scopes.phone.label": "View your phone number.",
	"consent.scopes.address.label": "View your address.",
	"consent.scopes.offlineAccess.label": "Keep you signed in to the app.",
	"consent.scopes.onlineAccess.label": "Keep you signed in to the app.",
	"consent.scopes.deviceSSO.label": "Request device secret.",
	"consent.scopes.openid.desc": "Signals that a request is an OpenID request.",
	"consent.scopes.profile.desc": "The exact data varies based on what profile information you have provided, such as: name, time zone, picture, or birthday.",
	"consent.scopes.email.desc": "This allows the app to view your email address.",
	"consent.scopes.address.desc": "This allows the app to view your address, such as: street address, city, state, and zip code.",
	"consent.scopes.phone.desc": "This allows the app to view your phone number.",
	"consent.scopes.offlineAccess.desc": "This keeps you signed in to the app, even when you are not using it.",
	"consent.scopes.onlineAccess.desc": "This allows the app to access your data only when you are logged in to the application.",
	"consent.scopes.deviceSSO.desc": "This allows you to single sign on between native client applications on your device.",
	"consent.scopes.okta.users.manage.desc": "Allows the app to create new users and to manage all users'' profile and credentials information.",
	"consent.scopes.okta.users.manage.self.desc": "Allows the app to manage the signed-in user''s profile and credentials.",
	"consent.scopes.okta.users.read.desc": "Allows the app to read the existing users'' profiles and credentials.",
	"consent.scopes.okta.users.read.self.desc": "Allows the app to read the signed-in user''s profile and credentials.",
	"consent.scopes.okta.linkedObjects.manage.desc": "Allows the app to manage linked object definitions in your Okta organization.",
	"consent.scopes.okta.linkedObjects.read.desc": "Allows the app to read linked object definitions in your Okta organization.",
	"consent.scopes.okta.myAccount.manage.desc": "Allows the end-user to manage their account profile.",
	"consent.scopes.okta.myAccount.read.desc": "Allows the end-user to read their account profile.",
	"consent.scopes.okta.riskEvents.manage.desc": "Allows the app to publish risk events to your Okta organization.",
	"consent.scopes.okta.clients.manage.desc": "Allows the app to manage clients in your Okta organization.",
	"consent.scopes.okta.clients.read.desc": "Allows the app to read information about clients in your Okta organization.",
	"consent.scopes.okta.clients.register.desc": "Allows the app to create new clients in your Okta organization.",
	"consent.scopes.okta.authorizationServers.manage.desc": "Allows the app to create and manage Authorization Servers in your Okta organization.",
	"consent.scopes.okta.authorizationServers.read.desc": "Allows the app to read information about Authorization Servers in your Okta organization.",
	"consent.scopes.okta.groups.manage.desc": "Allows the app to manage existing groups in your Okta organization.",
	"consent.scopes.okta.groups.read.desc": "Allows the app to read information about groups and their members in your Okta organization.",
	"consent.scopes.okta.groups.register.desc": "Allows the app to create new groups in your Okta organization.",
	"consent.scopes.okta.events.read.desc": "Allows the app to read information about deprecated Events v1 API entries in your Okta organization.",
	"consent.scopes.okta.logs.read.desc": "Allows the app to read information about System Log entries in your Okta organization.",
	"consent.scopes.okta.inlineHooks.manage.desc": "Allows the app to create and manage Inline Hooks in your Okta organization.",
	"consent.scopes.okta.inlineHooks.read.desc": "Allows the app to read information about Inline Hooks in your Okta organization.",
	"consent.scopes.okta.eventHooks.manage.desc": "Allows the app to create and manage Event Hooks in your Okta organization.",
	"consent.scopes.okta.eventHooks.read.desc": "Allows the app to read information about Event Hooks in your Okta organization.",
	"consent.scopes.okta.apps.manage.desc": "Allows the app to create and manage Apps in your Okta organization.",
	"consent.scopes.okta.apps.read.desc": "Allows the app to read information about Apps in your Okta organization.",
	"consent.scopes.okta.schemas.manage.desc": "Allows the app to create and manage Schemas in your Okta organization.",
	"consent.scopes.okta.schemas.read.desc": "Allows the app to read information about Schemas in your Okta organization.",
	"consent.scopes.okta.idps.manage.desc": "Allows the app to create and manage Identity Providers in your Okta organization.",
	"consent.scopes.okta.idps.read.desc": "Allows the app to read information about Identity Providers in your Okta organization.",
	"consent.scopes.okta.roles.manage.desc": "Allows the app to manage administrative role assignments for users in your Okta organization.",
	"consent.scopes.okta.roles.read.desc": "Allows the app to read administrative role assignments for users in your Okta organization.",
	"consent.scopes.okta.policies.manage.desc": "Allows the app to manage policies in your Okta organization.",
	"consent.scopes.okta.policies.read.desc": "Allows the app to read information about policies in your Okta organization.",
	"consent.scopes.okta.orgs.manage.desc": "Allows the app to manage organization-specific details for your Okta organization.",
	"consent.scopes.okta.orgs.read.desc": "Allows the app to read organization-specific details about your Okta organization.",
	"consent.scopes.okta.captchas.manage.desc": "Allows the app to create and manage CAPTCHAs in your Okta organization.",
	"consent.scopes.okta.captchas.read.desc": "Allows the app to read information about CAPTCHAs in your Okta organization.",
	"consent.scopes.okta.sessions.manage.desc": "Allows the app to manage all sessions in your Okta organization.",
	"consent.scopes.okta.sessions.read.desc": "Allows the app to read all sessions in your Okta organization.",
	"consent.scopes.okta.trustedOrigins.manage.desc": "Allows the app to manage all Trusted Origins in your Okta organization.",
	"consent.scopes.okta.trustedOrigins.read.desc": "Allows the app to read all Trusted Origins in your Okta organization.",
	"consent.scopes.okta.templates.manage.desc": "Allows the app to manage all custom templates in your Okta organization.",
	"consent.scopes.okta.templates.read.desc": "Allows the app to read all custom templates in your Okta organization.",
	"consent.scopes.okta.factors.manage.desc": "Allows the app to manage all admin operations for org factors (e.g. activate, deactivate, read).",
	"consent.scopes.okta.factors.read.desc": "Allows the app to read org factors information.",
	"consent.scopes.okta.authenticators.manage.desc": "Allows the app to manage all security methods (e.g. enrollments, reset).",
	"consent.scopes.okta.authenticators.read.desc": "Allows the app to read org security method information.",
	"consent.scopes.okta.authenticators.manage.self.desc": "Allows the app to manage users own security methods (e.g. enrollments, reset).",
	"consent.scopes.idSnapshot.read.desc": "Allows the app to read your identity snapshot attestation.",
	"consent.scopes.idSnapshot.manage.desc": "Allows the app to create and manage your identity snapshot attestation.",
	"logo.for.the.app.alt.text": "Logo for the app",
	"device.code.activate.title": "Activate your device",
	"device.code.activate.subtitle": "Follow the instructions on your device to get an activation code",
	"device.code.activate.label": "Activation Code",
	"device.code.activated.success.title": "Device activated",
	"device.code.activated.error.title": "Device not activated",
	"idx.device.activated": "Follow the instructions on your device for next steps",
	"idx.device.not.activated.consent.denied": "Your device cannot be activated because you did not allow access",
	"idx.device.not.activated.internal.error": "Your device cannot be activated because of an internal error",
	"idx.invalid.device.code": "Invalid code. Try again.",
	"idx.operation.cancelled.on.other.device": "Access denied on other device.",
	"idx.expired.activation.token": "Your account activation link is no longer valid. Request a new activation email below.",
	"idx.missing.activation.token": "This can happen if you have already activated your account, or if the URL you are trying to use is invalid. Contact your administrator for further assistance.",
	"idx.activating.inactive.user": "Your account has been temporarily suspended. Contact your administrator for further assistance.",
	"idx.request.activation.email": "New activation link requested. If your information matches our records, you will receive a new activation link in your inbox soon.",
	"oie.activation.request.email.title.expire": "Activation link has expired",
	"oie.activation.request.email.title.invalid": "Activation link no longer valid",
	"oie.activation.request.email.title.suspended": "Account suspended",
	"oie.activation.request.email.title.submitted": "Request submitted",
	"oie.activation.request.email.button": "Request activation email",
	"oie.browser.error.NotAllowedError": "The operation either timed out or was not allowed.",
	"authfactor.challenge.soft_token.invalid_passcode": "Your code doesn't match our records. Please try again.",
	"authfactor.challenge.soft_token.used_passcode": "Each code can only be used once. Please wait for a new code and try again.",
	"oie.tooManyRequests": "Too many attempts. Try again later.",
	E0000010: E0000010,
	"idx.error.registration.unavailable": "Registration is currently unavailable.",
	"idx.error.user.not.assigned.to.app": "User is not assigned to this application.",
	"idx.error.code.user_not_assigned": "You are not allowed to access this app. To request access, contact an admin.",
	"custom.identifiers.userNotResolved": "Can't identify your account with {0}. Enter your username.",
	"idx.error.code.access_denied.device_assurance.remediation.title": "Your device doesn't meet the security requirements",
	"idx.error.code.access_denied.device_assurance.remediation.explanation_one_rule": "To sign in, make the following updates. Then, access the app again.",
	"idx.error.code.access_denied.device_assurance.remediation.explanation_multiple_rules": "To sign in, pick an option and make the updates. Then, access the app again.",
	"idx.error.code.access_denied.device_assurance.remediation.option_index": "Option {0}:",
	"idx.error.code.access_denied.device_assurance.remediation.android.upgrade_os_version": "Update to Android {0}",
	"idx.error.code.access_denied.device_assurance.remediation.android.device_disk_encrypted": "Encrypt your device",
	"idx.error.code.access_denied.device_assurance.remediation.android.use_lock_screen": "Enable lock screen",
	"idx.error.code.access_denied.device_assurance.remediation.android.use_biometric_lock_screen": "Enable lock screen and biometrics",
	"idx.error.code.access_denied.device_assurance.remediation.ios.upgrade_os_version": "Update to iOS {0}",
	"idx.error.code.access_denied.device_assurance.remediation.ios.use_lock_screen": "Set a passcode for the lock screen",
	"idx.error.code.access_denied.device_assurance.remediation.ios.use_biometric_lock_screen": "Set a passcode for the lock screen and enable Touch ID or Face ID",
	"idx.error.code.access_denied.device_assurance.remediation.macos.upgrade_os_version": "Update to macOS {0}",
	"idx.error.code.access_denied.device_assurance.remediation.macos.use_lock_screen": "Set a passcode for the lock screen",
	"idx.error.code.access_denied.device_assurance.remediation.macos.device_disk_encrypted": "Turn on FileVault",
	"idx.error.code.access_denied.device_assurance.remediation.windows.upgrade_os_version": "Update to Windows {0}",
	"idx.error.code.access_denied.device_assurance.remediation.windows.device_disk_encrypted": "Encrypt all internal disks with BitLocker",
	"idx.error.code.access_denied.device_assurance.remediation.windows.use_biometric_lock_screen": "Enable Windows Hello for the lock screen",
	"idx.error.code.access_denied.device_assurance.remediation.additional_help_default": "For more information, follow the instructions on <$1>the help page</$1> or contact your administrator for help",
	"idx.error.code.access_denied.device_assurance.remediation.additional_help_custom": "For more information, follow the instructions on <$1>your organization's help page</$1> or contact your administrator for help",
	"idx.error.code.access_denied.device_assurance.remediation.chrome.disk_encrypted": "Turn on disk encryption",
	"idx.error.code.access_denied.device_assurance.remediation.chrome.os_firewall": "Turn on your device's firewall",
	"idx.error.code.access_denied.device_assurance.remediation.chrome.screen_lock_secured": "Turn on automatic screen saver and screen locking when idle",
	"idx.error.code.access_denied.device_assurance.remediation.chrome.upgrade_browser_version": "Update Chrome browser to {0}",
	"idx.error.code.access_denied.device_assurance.remediation.chrome.chromeos.upgrade_os_version": "Update to ChromeOS {0}",
	"idx.error.code.access_denied.device_assurance.remediation.chrome.chromeos.key_trust_level.verified_mode": "Switch your device to verified mode",
	"idx.error.code.access_denied.device_assurance.remediation.chrome.chromeos.key_trust_level.developer_mode": "Switch your device to developer mode"
};

export { E0000010, android, autoPush, closeWindow, days, login as default, forgotpassword, goback, haveaccount, help, hours, iphone, minutes, needhelp, remember, rememberDevice, retry, signin, signout, signup, tagsNotAllowed, unlockaccount };

