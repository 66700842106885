import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatIconButton } from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoModule } from '@jsverse/transloco';

import { BELL_ICON_COMPONENT_TOKEN } from '@shure/cloud/shared/utils/config';

import { NotificationsBellIconMainComponent } from './notifications-bell-icon-main.component';
import { NotificationDetailsComponent } from './ui/components/notification-details/notification-details.component';
import { NotificationsPopupComponent } from './ui/components/notifications-popup/notifications-popup.component';

@NgModule({
	declarations: [NotificationsBellIconMainComponent, NotificationDetailsComponent, NotificationsPopupComponent],
	exports: [NotificationsBellIconMainComponent, NotificationDetailsComponent],
	imports: [
		CommonModule,
		MatListModule,
		MatSlideToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatTabsModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatInputModule,
		TranslocoModule,
		MatIcon,
		MatIconButton,
		MatBadge,
		MatButtonModule,
		MatDivider
	],
	providers: [{ provide: BELL_ICON_COMPONENT_TOKEN, useValue: NotificationsBellIconMainComponent }]
})
export class NotificationsBellIconModule {}
