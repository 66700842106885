import { Directive, forwardRef, ElementRef } from '@angular/core';

import { PermissionsActionDirective } from './permissions-action';

@Directive({
	selector: '[shPermissionsRemove]',
	providers: [
		{
			provide: PermissionsActionDirective,
			useExisting: forwardRef(() => PermissionsActionRemoveDirective)
		}
	]
})
export class PermissionsActionRemoveDirective extends PermissionsActionDirective {
	constructor(private el: ElementRef) {
		super();
	}

	public execute(hasPermission: boolean): void {
		if (!hasPermission) {
			this.el.nativeElement.remove();
		}
	}
}
