import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { OktaAuthGuard, OktaAuthModule, OktaCallbackComponent } from '@okta/okta-angular';

import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { SigninGuard, SignInComponent } from '@shure/cloud/shared/okta/feature-okta-signin';

/**
 * The Device Manager deviates from the other cloud apps by using both the OktaAuthGuard and the oktaSessionGuard.
 * The other apps are only using the oktaSessionGuard. This is because they each have different signin and signout
 * requirements, and also different requirements for what should happen when a user has multiple tabs open
 * and they logout of any of them.
 * - For the CDM:
 *   - The user should be able to login by first logging into the Cloud Portal, and then launching the
 *     CDM from the Cloud Portal menu of applications. In this manner, the user is automatically
 *     logged into the CDM.
 *   - The other way to login is to directly navigate to CDM URL w/out first logging into the Cloud Portal. In
 *     this manner, they will be redirected to Okta to enter their credentials.
 *   - When the user logs out, in general we redirect to the cloud portal. We don't stay on the same URL.
 *   - Also, when the user logs out and they have multiple tabs open, we want all tabs to immediately be logged out;
 *     we don't want to wait for the user to click on a route for the end-of-session to be recognized.
 *
 * - The OktaAuthGuard allows the CDM to detect when the user has logged out of any CDM tab, and either
 *   redirect to the /signin page (no prior session), or to the logout URL when the user has logged out.
 * - The sessionGuard protects against routing when the session has ended with Okta.
 */

const appRoutes: Route[] = [
	{
		path: '',
		redirectTo: 'devices',
		pathMatch: 'full'
	},
	{
		path: 'signin',
		component: SignInComponent,
		canActivate: [SigninGuard]
	},

	{
		path: 'devices',
		loadChildren: () =>
			import('@shure/cloud/device-management/devices/feature-inventory').then((m) => m.InventoryMainModule),
		canActivate: [OktaAuthGuard, oktaSessionGuard]
	},
	{
		path: 'pending-devices',
		loadChildren: () =>
			import('@shure/cloud/device-management/devices/feature-pending').then((m) => m.PendingDevicesModule),
		canActivate: [OktaAuthGuard, oktaSessionGuard]
	},
	{
		path: 'account',
		loadComponent: () => import('../components/my-account/my-account.component').then((m) => m.MyAccountComponent),
		canActivate: [OktaAuthGuard, oktaSessionGuard]
	},
	{
		path: 'signin/callback',
		component: OktaCallbackComponent
	},
	// ** is the "page not found" route.
	// Try to go to /devices. If the user isn't logged in, the auth guard
	// will redirect them to the Signin Page.
	{
		path: '**',
		redirectTo: 'devices',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			initialNavigation: 'enabledBlocking',
			useHash: false // must be false for okta signin/callback to route.
		}),
		OktaAuthModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
