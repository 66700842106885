import { _ as _with } from '../../../../../../_virtual/with.js';
import '../utils.js';
import '../exception.js';
import { __exports as utils } from '../../../../../../_virtual/utils.js';
import { e as exception } from '../../../../../../_virtual/exception.js';

(function (module, exports) {
  exports.__esModule = true; // istanbul ignore next

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      'default': obj
    };
  }
  var _utils = utils;
  var _exception = exception.exports;
  var _exception2 = _interopRequireDefault(_exception);
  exports['default'] = function (instance) {
    instance.registerHelper('with', function (context, options) {
      if (arguments.length != 2) {
        throw new _exception2['default']('#with requires exactly one argument');
      }
      if (_utils.isFunction(context)) {
        context = context.call(this);
      }
      var fn = options.fn;
      if (!_utils.isEmpty(context)) {
        var data = options.data;
        if (options.data && options.ids) {
          data = _utils.createFrame(options.data);
          data.contextPath = _utils.appendContextPath(options.data.contextPath, options.ids[0]);
        }
        return fn(context, {
          data: data,
          blockParams: _utils.blockParams([context], [data && data.contextPath])
        });
      } else {
        return options.inverse(this);
      }
    });
  };
  module.exports = exports['default'];
})(_with, _with.exports);

