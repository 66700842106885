/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateOrganizationsDto } from '../../models/create-organizations-dto';
import { OrganizationDetails } from '../../models/organization-details';

export interface CreateVerifiedOrganization$Params {
      body: CreateOrganizationsDto
}

export function createVerifiedOrganization(http: HttpClient, rootUrl: string, params: CreateVerifiedOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'statusCode'?: number;
'body'?: {
'message'?: string;
'messageKey'?: string;
'data'?: OrganizationDetails;
};
}>> {
  const rb = new RequestBuilder(rootUrl, createVerifiedOrganization.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'statusCode'?: number;
      'body'?: {
      'message'?: string;
      'messageKey'?: string;
      'data'?: OrganizationDetails;
      };
      }>;
    })
  );
}

createVerifiedOrganization.PATH = '/api/v1';
