import { l as logger } from '../../../../../_virtual/logger.js';
import './utils.js';
import { __exports as utils } from '../../../../../_virtual/utils.js';

(function (module, exports) {
  exports.__esModule = true;
  var _utils = utils;
  var logger = {
    methodMap: ['debug', 'info', 'warn', 'error'],
    level: 'info',
    // Maps a given level value to the `methodMap` indexes above.
    lookupLevel: function lookupLevel(level) {
      if (typeof level === 'string') {
        var levelMap = _utils.indexOf(logger.methodMap, level.toLowerCase());
        if (levelMap >= 0) {
          level = levelMap;
        } else {
          level = parseInt(level, 10);
        }
      }
      return level;
    },
    // Can be overridden in the host environment
    log: function log(level) {
      level = logger.lookupLevel(level);
      if (typeof console !== 'undefined' && logger.lookupLevel(logger.level) <= level) {
        var method = logger.methodMap[level]; // eslint-disable-next-line no-console

        if (!console[method]) {
          method = 'log';
        }
        for (var _len = arguments.length, message = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          message[_key - 1] = arguments[_key];
        }
        console[method].apply(console, message); // eslint-disable-line no-console
      }
    }
  };

  exports['default'] = logger;
  module.exports = exports['default'];
})(logger, logger.exports);

