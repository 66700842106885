import { commonjsGlobal } from '../../../../../../_virtual/_commonjsHelpers.js';
import { e as each } from '../../../../../../_virtual/each.js';
import '../utils.js';
import '../exception.js';
import { __exports as utils } from '../../../../../../_virtual/utils.js';
import { e as exception } from '../../../../../../_virtual/exception.js';

(function (module, exports) {
  exports.__esModule = true; // istanbul ignore next

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      'default': obj
    };
  }
  var _utils = utils;
  var _exception = exception.exports;
  var _exception2 = _interopRequireDefault(_exception);
  exports['default'] = function (instance) {
    instance.registerHelper('each', function (context, options) {
      if (!options) {
        throw new _exception2['default']('Must pass iterator to #each');
      }
      var fn = options.fn,
        inverse = options.inverse,
        i = 0,
        ret = '',
        data = undefined,
        contextPath = undefined;
      if (options.data && options.ids) {
        contextPath = _utils.appendContextPath(options.data.contextPath, options.ids[0]) + '.';
      }
      if (_utils.isFunction(context)) {
        context = context.call(this);
      }
      if (options.data) {
        data = _utils.createFrame(options.data);
      }
      function execIteration(field, index, last) {
        if (data) {
          data.key = field;
          data.index = index;
          data.first = index === 0;
          data.last = !!last;
          if (contextPath) {
            data.contextPath = contextPath + field;
          }
        }
        ret = ret + fn(context[field], {
          data: data,
          blockParams: _utils.blockParams([context[field], field], [contextPath + field, null])
        });
      }
      if (context && typeof context === 'object') {
        if (_utils.isArray(context)) {
          for (var j = context.length; i < j; i++) {
            if (i in context) {
              execIteration(i, i, i === context.length - 1);
            }
          }
        } else if (commonjsGlobal.Symbol && context[commonjsGlobal.Symbol.iterator]) {
          var newContext = [];
          var iterator = context[commonjsGlobal.Symbol.iterator]();
          for (var it = iterator.next(); !it.done; it = iterator.next()) {
            newContext.push(it.value);
          }
          context = newContext;
          for (var j = context.length; i < j; i++) {
            execIteration(i, i, i === context.length - 1);
          }
        } else {
          (function () {
            var priorKey = undefined;
            Object.keys(context).forEach(function (key) {
              // We're running the iterations one step out of sync so we can detect
              // the last iteration without have to scan the object twice and create
              // an itermediate keys array.
              if (priorKey !== undefined) {
                execIteration(priorKey, i - 1);
              }
              priorKey = key;
              i++;
            });
            if (priorKey !== undefined) {
              execIteration(priorKey, i - 1, true);
            }
          })();
        }
      }
      if (i === 0) {
        ret = inverse(this);
      }
      return ret;
    });
  };
  module.exports = exports['default'];
})(each, each.exports);

