import { Directive, forwardRef, ElementRef, Renderer2 } from '@angular/core';

import { PermissionsActionDirective } from './permissions-action';

@Directive({
	selector: '[shPermissionsReadOnly]',
	providers: [
		{
			provide: PermissionsActionDirective,
			useExisting: forwardRef(() => PermissionsActionReadOnlyDirective)
		}
	]
})
export class PermissionsActionReadOnlyDirective extends PermissionsActionDirective {
	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) {
		super();
	}

	public execute(): void {
		if (this.el.nativeElement.tagName.toLowerCase() === 'input') {
			this.renderer.setAttribute(this.el.nativeElement, 'readonly', 'readonly');
		} else {
			const inputs = this.el.nativeElement.querySelectorAll('input');
			inputs.forEach((input: unknown) => this.renderer.setAttribute(input, 'readonly', 'readonly'));
		}
	}
}
