import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';
import { CloseTextOption, SnackbarService } from '@shure/cloud/shared/ui/components';
import { ApiUsersService } from '@shure/cloud/shared/users/data-access';
import { OrganizationsApiService } from '@shure/cloud/shure-associate/organizations/data-access';

import { PermissionsService, ShPermissionsAggregate } from '../services/permissions.service';

export enum Apps {
	AssociatePortal = 'AssociatePortal'
}
export interface MaintenanceResponse {
	maintenance: boolean;
	banner: boolean;
	startAt: string;
	endAt: string;
	message: string;
}
@Injectable({ providedIn: 'root' })
export class PermissionsGuard {
	public permissions!: string | ShPermissionsAggregate;
	public appName!: string;
	private destroy$: Subject<void> = new Subject<void>();
	private maintenanceData!: MaintenanceResponse | null;

	constructor(
		private snackBarService: SnackbarService,
		private permissionService: PermissionsService,
		private apiOrganizationsService: OrganizationsApiService,
		public organizationStoreService: OrganizationsStoreService,
		private translocoService: TranslocoService,
		private authStateService: OktaAuthStateService,
		private http: HttpClient,
		private apiUsersService: ApiUsersService
	) {}

	public canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
		// eslint-disable-next-line dot-notation
		this.permissions = route.data['permissions'];
		// eslint-disable-next-line dot-notation
		this.appName = route.data['appName'];
		return this.authStateService.authState$.pipe(
			take(1),
			switchMap((authState: AuthState) => (!authState.isAuthenticated ? of(false) : this.doCheck()))
		);
	}
	private doCheck(): Observable<boolean> {
		const appName = this.appName === Apps.AssociatePortal ? 'adminportal' : 'organizationalportal';
		this.organizationStoreService
			.getMaintenance()
			.pipe(takeUntil(this.destroy$))
			.subscribe((val: MaintenanceResponse | null) => {
				this.maintenanceData = val;
			});
		const appPermissions$ =
			this.appName === 'AssociatePortal'
				? this.permissionService.getAdminPermissions$().pipe(catchError((error) => of(error)))
				: this.permissionService.getPermissions$().pipe(catchError((error) => of(error)));
		const versions$ = this.apiOrganizationsService
			.getVersion$Response({ appName: appName })
			.pipe(catchError((error) => of(error)));
		const accountInformationKeys = Object.keys(this.organizationStoreService.accountInformation());
		let accountInfo$ =
			accountInformationKeys.length <= 0
				? this.apiUsersService.getSessionAccountInfo$Response().pipe(catchError((error) => of(error)))
				: of(null);
		return this.organizationStoreService.getMaintenance().pipe(
			tap(() => {
				const accountInformationKeys = Object.keys(this.organizationStoreService.accountInformation());
				if (accountInformationKeys.length > 0) {
					accountInfo$ = of(null);
				}
			}),
			// Wait for getMaintenance() to be resolved before proceeding
			switchMap(() => {
				return forkJoin({
					permission: appPermissions$,
					version: versions$,
					maintenance:
						appName === 'organizationalportal' && this.maintenanceData === null
							? this.http.get('./maintenanceConfig.json').pipe(catchError((error) => of(error)))
							: of(null),
					accountInfo: accountInfo$
				}).pipe(
					map((data) => {
						this.organizationStoreService.setVersion(data.version?.body?.body?.version);
						if (data?.accountInfo?.body?.body) {
							this.organizationStoreService.setAccountInfo(data.accountInfo?.body?.body);
						}
						if (data?.maintenance) {
							this.organizationStoreService.setMaintenance(data.maintenance);
						}
						if (this.permissions) {
							return this.testPermissions();
						}
						return true;
					})
				);
			})
		);
	}

	private testPermissions(): boolean {
		const result = this.permissionService.testPermissions(this.permissions);
		if (!result) {
			this.snackBarService.open(this.translocoService.translate('access-error-message'), CloseTextOption.Ok);
		}
		return result;
	}
}
